import React from "react";
import Header from "../../../components/Header/Header";
import EventsIMG from "../../../img/Events6.svg";
import Footer from "../../../components/Footer/Footer";
const Events = () => {
  return (
    <div className="custom-managment-page">
      <Header />
      <div className="insight-container">
        <img src={EventsIMG} alt="Image" />
        <div className="insight-content" style={{ paddingBottom: "0px" }}>
          <header>
            <h2>SFVegas 2022</h2>
          </header>
        </div>
        <section className="insight-sub-content-1">
          <p>
            Intain Founder and CEO, Siddhartha, will be on the panel on
            Technology Trends in Securitization. Also, visit our booth to know
            more about how structured finance administration on blockchain is
            now mainstream, and tokenized structured finance will be a reality
            within this year.
          </p>
        </section>
      </div>
    </div>
  );
};

const Events6 = () => {
  return (
    <div>
      <Events />
      <Footer />
    </div>
  );
};

export default Events6;
