import React,{useState,useEffect} from "react";
import Header from "../../../components/Header/Header";
import Insightimg from "../../../img/InsightOne.svg";
import Challenge from "../../../img/challenge.svg";
import Challangedark from "../../../img/Challenge-dark.png"
import Footer from "../../../components/Footer/Footer";
const Insight = () => {
  const [isDarkMode, setIsDarkMode] = useState(
    sessionStorage.getItem("dark-theme") === "dark"
  );
  useEffect(() => {
    const checkTheme = () => {
      const darkTheme = sessionStorage.getItem("dark-theme") === "dark";
      setIsDarkMode(darkTheme);
    };

    window.addEventListener("storage", checkTheme);
    const interval = setInterval(checkTheme, 10); // Checking every half second if the theme changes

    return () => {
      window.removeEventListener("storage", checkTheme);
      clearInterval(interval); // Clean up the interval on component unmount
    };
  }, []);

  return (
    <div className="custom-managment-page">
      <Header />
      <div className="insight-container">
        <img src={Insightimg} alt="Image" />
        <div className="insight-content">
          <header className="insight-header">
            <h2>
              Intain DeFi — Transparency, Trust and Liquidity — In that Order
            </h2>
            <h3>
              In Capital Markets, DeFi Is About Tokenized Assets and Securities,
              But It Does Not Seem To Be Working
            </h3>
            <span>Siddhartha S.</span>
            <div className="insight-content-date">June 6, 2021</div>
          </header>

          <section className="insight-sub-content">
            <h2>Tokenization — Why the current model does not work?</h2>
            <p>
              “REITs trade at 10% discount to the value of real estate. So, if
              fractionalization is all that I will achieve through ‘DeFi real
              estate investment’, I will lose $100M on a billion-dollar
              building”, quipped a real estate expert among some blockchain
              believers...
            </p>
          </section>

          <section className="insight-sub-content-1">
            <h4>
              Lesson One — Fractionalization per se is not value accreditive.
            </h4>
            <p>
              But one thing I still knew is that investors know more about a
              fund from Fidelity or Vanguard, than they know about the St. Regis
              Aspen token because...
            </p>
          </section>

          <section className="insight-sub-content-1">
            <h4>
              Lesson Two — DeFi does not automatically imply more transparency.
            </h4>
            <p>
              As those who launched the securities token secondary markets as
              ATSs will tell us, there isn’t that much liquidity either...
            </p>
          </section>

          <section className="insight-sub-content-1">
            <h4>Lesson Three — DeFi does not create liquidity by default.</h4>
            <p>
              At Intain, our consistent view has been that transparency is a
              pre-condition for liquidity. In fact, that is the core promise of
              blockchain...
            </p>
          </section>

          <section className="insight-sub-content challenge">
            <h2>The Solution — The Challenge</h2>
            <p>
              Given the structure of most financial instruments, blockchains can
              help with disintermediation by making the underlying asset
              transparent and trustworthy — whether it is a hotel or pool of
              loans or oil wells. Alternative is what &nbsp;
              <a href="https://www.theasset.com/article/43218/citi-named-custodian-for-bondevalue-fractionalized-bonds">
                BondEvalue has done by bringing Citi and Northern Trust onboard
                as custodians. Here the trust is being ensured by the custodians
              </a>
               . So, you can achieve transparency and trust through financial
              intermediaries or by bringing the asset economics on blockchain.
              There is little chance of success for tokens being issued in their
              current forms which do neither.{" "}
            </p>
            <p>
              Putting the economics of the underlying assets on chain is easier
              said than done. It will involve parties other than the issuer who
              have no incentive to get on to a public blockchain. This may
              include counterparties, auditors, accountants, administrators,
              trustees etc.
            </p>
            <p>
              Conceptually, it would make sense to have this run on a
              private-permissioned blockchain that publishes data on to the
              public blockchains where the tokens are issued and traded. This is
              same as BNY Melon or State Street publishing Net Asset Value (NAV)
              for mutual funds, only that here that credibility of the ‘NAV’ has
              been established using a blockchain. Smart contracts are
              calculating the NAVs and integrity of required documents and other
              artefacts is maintained on the private blockchain. In absence of
              inter-operability between such a private chain and an exchange
              which is on a public blockchain, Intain team  presented a
              workaround (admittedly, a crude one) at the Hyperledger Global
              Forum in Tokyo in 2019.{" "}
            </p>
            <img src={isDarkMode ? Challenge :Challangedark} alt="Challenge" className="challenge-image" />
            <p>
              Around that time, we also did a proof of concept about putting
              some shale oil wells under an SPV whose shares were to be
              tokenized. Token issuance was on one of the leading issuance
              platforms but the whole oil well performance data and its
              economics was being tracked and calculated on 
              <a href="https://intainft.com/">Intain AVB </a>(Asset Value
              Blockchain) which was permissioned blockchain built on Hyperledger
              Fabric. Intain AVB had accountant, auditor, oil companies/owners,
              a third-party oil extraction data validator and the SPV manager as
              stakeholders. This was a slightly more refined version of Tokyo
              concept note, with Intain AVB receiving transfer agency data while
              sharing the NAV data with the issuance platform.
            </p>
            <p>
              All these are workarounds and are not based on composability. But
              the consistent goal has been to bring information about the
              underlying asset on-chain.
            </p>
          </section>

          <section className="insight-sub-content">
            <h2>Intain Defi</h2>
            <p>
              Now, that we have more $2B in Structured Finance assets being
              administered and serviced on Intain ABS and increasing by couple
              of hundred million each month, we have a clearer view into this.
              Since we have modeled some fairly complex structured products
              using smart contracts, we are also more confident about putting
              structure of any security on the blockchain — this is important if
              we want to ensure that an investor in a token has view into the
              underlying asset without any fear of the data being tampered with
              in between.{" "}
            </p>
            <p>
              Intain will work towards launch of its investment platform in Q1
              of 2022. We will do it for asset classes where Intain is already
              the servicing and administration platform with Intain AVB. This
              will ensure that Intain’s tokens are structured on-chain and
              issued on-chain with asset servicing and administration on-chain.
              It would mean that both the provenance of token and the NAV are
              established with immutable, auditable ledgers. With transparency
              and trust established, fractionalization will complete the three
              pre-requisites for liquidity.
            </p>
            <p>
              Our first step towards this will be linking our invoice factoring
              blockchain with a third-party token issuance platform. We are
              working towards a Q4, 2021 deadline and we should be able make an
              announcement in next six to eight weeks.
            </p>
            <p>
              While financial institutions see the merit in Intain’s approach,
              some DeFi experts have raised two concerns:
            </p>
          </section>
          <section className="insight-sub-content-2">
            <div>
              <span>1. There is no DeFi without composability : </span>
              <p>
                In absence of open and seamless connection with other
                applications, Intain applications, Intain approach doesn’t
                provide for composability. We somewhat agree. We do not think
                pushing Intain AVB on to a public blockchain is a viable option
                today. Hence, we are using alternative mechanisms to share asset
                data with issuing and trading platforms. There are multiple
                parties involved in structuring and servicing tokenized assets
                and securities. Even if one of them refuses to be on a public
                blockchain, it will fail.
              </p>
            </div>
          </section>
          <section className="insight-sub-content-2">
            <div>
              <span>
                2. No one uses smart contracts for such complex calculations : 
              </span>
              <p>
                {" "}
                We completely agree, and when we  interview people who have
                worked on various blockchain applications, this gets reinforced.
                But the payment waterfall of a credit derivative is complex
                because that structure may have been needed to meet the
                objectives of the issuer and the investor. As financial services
                professionals, we would push the envelope for smart contracts to
                deliver what we need, rather than hope FIs adjust to the
                limitations of the technology.
              </p>
            </div>
          </section>
          <section className="insight-sub-content">
            <h2>Next Twelve Months</h2>
            <p>
              It is very possible that simple structures of tokenized assets and
              securities, with composable DApps, all running on public
              blockchains is the future. It is possible that we, conditioned by
              our backgrounds in financial services operations and technology,
              are not able to reimagine things with a zero base (a criticism
              that recent ING paper on DeFi also has faced).
            </p>
            <p>
              But what we are sure of is this — in less than six months, an SME
              would be able to click a button while raising an invoice to get it
              factored (or insured). The factoring company, the accounting firm
              and the SME all on the Intain AVB. An investor will be able to
              invest in tokens backed by these pools of receivables with a level
              of assurance that no current DeFi platform offers.
              <b>
                Linking the SME, who needs working capital, to the investor, who
                is seeking returns in this manner, is our understanding of DeFi!{" "}
              </b>
            </p>

            <p>
              And yes,
              <b>
                in H1 2022, investors will be able to invest in loan pools with
                the ease of P2P
              </b>
              lending but with the risk mitigation benefits of a structured
              asset backed security (and yes, those ‘complex’ smart contracts
              will enable this)!
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};
const InsightOne = () => {
  return (
    <div>
      <Insight />
      <Footer />
    </div>
  );
};

export default InsightOne;
