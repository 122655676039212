import React, { useState } from "react";
import Header from "../../components/Header/Header";
import { ArrowRightAlt } from "@mui/icons-material";
import Footer from "../../components/Footer/Footer";
import ReCAPTCHA from "react-google-recaptcha";
import { ContactApi } from "../../Actions/Action";
import { Box } from "@mui/material";

// import reCAPTCHA from "react-google-recaptcha"
// const RECAPTCHA_SITE_KEY = "6Lc4KVwqAAAAAAGo-Y_VVLpsAR7fBTx4FYO8rU9v";

const RECAPTCHA_SITE_KEY = "6LdWIYYqAAAAAFKEtiNjf5gz0kSKfZ_jIGNAlybI";
const offices = [
  {
    title: "Registered Office",
    address: "251 Little Falls Dr, Wilmington New Castle, DE 19808, USA",
    directionsUrl:
      "https://www.google.com/maps?q=251+Little+Falls+Dr,+Wilmington+New+Castle,+DE+19808,+USA",
  },
  {
    title: "United States",
    address: "80 Broad Street,5th Floor (PMB#0843)New York, NY 10004.",
    directionsUrl: "https://maps.app.goo.gl/ZJQHJJFkvjGBjAnV7",
  },
  {
    title: "India",
    address:
      "3A-Sri Durga Enclave, Plot no 27B, River View Residency 2nd Street, Karapakkam, Chennai–600 097, India.",
    directionsUrl: "https://maps.app.goo.gl/7RFrVDL28fxnJrDx7",
  },
];

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    company: "",
    role: "",
    interest: "",
    companyMail: "",
    message: "",
    isRobot: false,
  });
  const [loader, setLoader] = useState(false);

  const roleOptions = [
    { value: "Accounting Firm", label: "Accounting Firm" },
    { value: "Aggregator", label: "Aggregator" },
    { value: "Arrangers / Advisory", label: "Arrangers / Advisory" },
    { value: "Asset Manager", label: "Asset Manager" },
    { value: "Bank Lender", label: "Bank Lender" },
    { value: "Broker Dealer", label: "Broker Dealer" },
    { value: "CDO / CLO Manager", label: "CDO / CLO Manager" },
    { value: "Individual", label: "Individual" },
    { value: "Institutional Investor", label: "Institutional Investor" },
    { value: "Issuer", label: "Issuer" },
    { value: "Law Firm", label: "Law Firm" },
    { value: "Mortgage Insurer", label: "Mortgage Insurer" },
    { value: "Originator", label: "Originator" },
    {
      value: "Other Financial Institution",
      label: "Other Financial Institution",
    },
    { value: "Rating Agency", label: "Rating Agency" },
    { value: "Servicer", label: "Servicer" },
    { value: "Trustee", label: "Trustee" },
    { value: "Underwriter", label: "Underwriter" },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    await ContactApi(formData); // Assume formData is the data object you're passing to the API
    setLoader(false);
    setFormData({
      name: "",
      company: "",
      role: "",
      interest: "",
      companyMail: "",
      message: "",
      isRobot: false,
    });
  };

  const handleGetDirections = (url) => {
    window.open(url, "_blank"); // Opens directions in a new tab
  };

  const onRecaptchaChange = (value) => {
    console.log("Recaptcha value:", value);
    // Set the formData 'isRobot' field to true or false based on the recaptcha result
    setFormData({ ...formData, isRobot: value ? true : false });
  };

  return (
    <Box className="custom-managment-page">
      <div className="team-title platform" style={{ marginTop: "150px" }}>
        <h1>Contact Us</h1>
        <span></span>
        <p className="contact-us-p">Let us know how we can help you</p>
      </div>
      <Box className="contact-us-container">
        <div className="contact-us-form">
          <form onSubmit={handleSubmit}>
            <div className="contact-us-container-form-input">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="contact-us-container-form-input">
              <label htmlFor="company">Company</label>
              <input
                type="text"
                id="company"
                name="company"
                value={formData.company}
                onChange={handleInputChange}
              />
            </div>
            <div className="contact-us-container-form-input">
              <label htmlFor="role">Role</label>
              <select
                id="role"
                name="role"
                value={formData.role}
                onChange={handleInputChange}
              >
                <option value="">Select Role</option>
                {roleOptions.map((role) => (
                  <option key={role.value} value={role.value}>
                    {role.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="contact-us-container-form-input">
              <label htmlFor="interest">Interest</label>
              <select
                id="interest"
                name="interest"
                value={formData.interest}
                onChange={handleInputChange}
              >
                <option value="">Select Interest</option>
                <option value="intainadmin">
                  IntainADMIN(Integrated Data Operations)
                </option>
                <option value="intainmarket">
                  IntainMARKETS(Digital Issuance and Admin)
                </option>
                <option value="intainai">
                  IntainAI(Automated Document Processing)
                </option>
                <option value="Genear">General Enquiry</option>
              </select>
            </div>
            <div className="contact-us-container-form-input">
              <label htmlFor="companyMail">Company Mail ID</label>
              <input
                type="email"
                id="companyMail"
                name="companyMail"
                value={formData.companyMail}
                onChange={handleInputChange}
              />
            </div>
            <div className="contact-us-container-form-input">
              <label htmlFor="message">Message</label>
              <textarea
                style={{ height: "100px", padding: "10px" }}
                id="message"
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                maxLength="500"
              />
            </div>
            <div className="recaptcha-wrapper">
              <ReCAPTCHA
                sitekey={RECAPTCHA_SITE_KEY}
                onChange={onRecaptchaChange}
              />
            </div>
            <button type="submit">{loader ? "Loading..." : "Submit"}</button>
            <label style={{ paddingTop: "20px" }}>
              By clicking submit application, You understand and agree to the
              terms of use and <a href="/privacy-policy">privacy policy</a>.
            </label>
          </form>
        </div>
      </Box>

      <Box>
        <div className="team-title platform ofc">
          <h1>Intain Offices</h1>
          <span></span>
        </div>
        <div className="ofc-container-wrapper">
          {offices.map((office, index) => (
            <div className="ofc-container" key={index}>
              <span> {office.title}</span>
              <label
                style={{
                  ...(office.title === "India"
                    ? { position: "relative", bottom: "2rem" }
                    : {}),
                }}
              >
                {office.address}
              </label>
              <p
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  ...(office.title === "India"
                    ? { top: "3px", bottom: "0px" }
                    : {}),

                  // Apply custom styling for Branch Office
                  //   ...(office.title === "India" ? { bottom: "60px" } : {}),
                }}
                onClick={() => handleGetDirections(office.directionsUrl)}
              >
                Get Directions
                <ArrowRightAlt sx={{ fontSize: 30 }} />
              </p>
            </div>
          ))}
        </div>
      </Box>
    </Box>
  );
};

const Contactus = () => {
  return (
    <div>
      <Header />
      <Contact />
      <Footer />
    </div>
  );
};

export default Contactus;
