import React, { useState } from "react";
import intainlogo from "../../img/intainlogo-3.png";
import { Box } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { register } from "../../Actions/Action";

// import { GoogleLogin } from "@react-oauth/google";
// import { jwtDecode } from "jwt-decode"; // Correct way to import
const RECAPTCHA_SITE_KEY = "6LdWIYYqAAAAAFKEtiNjf5gz0kSKfZ_jIGNAlybI";

const SignUp = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    company: "",
    role: "",
    interest: "",
    companyMail: "",
    password: "",
    confirmPassword: "",
  });

  const [errorMessage, setErrorMessage] = useState(""); // State to hold error messages
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState(false);
  const roleOptions = [
    { value: "Accounting Firm", label: "Accounting Firm" },
    { value: "Aggregator", label: "Aggregator" },
    { value: "Arrangers / Advisory", label: "Arrangers / Advisory" },
    { value: "Asset Manager", label: "Asset Manager" },
    { value: "Bank Lender", label: "Bank Lender" },
    { value: "Broker Dealer", label: "Broker Dealer" },
    { value: "CDO / CLO Manager", label: "CDO / CLO Manager" },
    { value: "Individual", label: "Individual" },
    { value: "Institutional Investor", label: "Institutional Investor" },
    { value: "Issuer", label: "Issuer" },
    { value: "Law Firm", label: "Law Firm" },
    { value: "Mortgage Insurer", label: "Mortgage Insurer" },
    { value: "Originator", label: "Originator" },
    {
      value: "Other Financial Institution",
      label: "Other Financial Institution",
    },
    { value: "Rating Agency", label: "Rating Agency" },
    { value: "Servicer", label: "Servicer" },
    { value: "Trustee", label: "Trustee" },
    { value: "Underwriter", label: "Underwriter" },
  ];

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prev) => !prev);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrorMessage("");
  };

  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      setErrorMessage("Passwords do not match!");
      return;
    }
    await register(formData);
    setLoader(false);
    navigate("/"); // Redirect to login page
  };

  const onRecaptchaChange = (value) => {
    console.log("Recaptcha value:", value);
    // Set the formData 'isRobot' field to true or false based on the recaptcha result
    setFormData({ ...formData, isRobot: value ? true : false });
  };

  return (
    <Box className="login-page custom-managment-page" style={{overflowX:"hidden"}}>
      <img src={intainlogo} alt="Logo" onClick={() => navigate("/")} />
      <div className="contact-us-container-signup">
        <div className="contact-us-form sign-up">
          <form onSubmit={handleSubmit}>
            <h2>Sign Up</h2>
            <div className="contact-us-container-form-input">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="contact-us-container-form-input">
              <label htmlFor="company">Company</label>
              <input
                type="text"
                id="company"
                name="company"
                value={formData.company}
                onChange={handleInputChange}
              />
            </div>
            <div className="contact-us-container-form-input">
              <label htmlFor="role">Role</label>
              <select
                id="role"
                name="role"
                value={formData.role}
                onChange={handleInputChange}
              >
                <option value="">Select Role</option>
                {roleOptions.map((role) => (
                  <option key={role.value} value={role.value}>
                    {role.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="contact-us-container-form-input">
              <label htmlFor="interest">Interest</label>
              <select
                id="interest"
                name="interest"
                value={formData.interest}
                onChange={handleInputChange}
              >
                <option value="">Select Interest</option>
                <option value="intainadmin">
                  IntainADMIN(Integrated Data Operations)
                </option>
                <option value="intainmarket">
                  IntainMARKETS(Digital Issuance and Admin)
                </option>
                <option value="intainai">
                  IntainAI(Automated Document Processing)
                </option>
                <option value="Genear">General Enquiry</option>
              </select>
            </div>
            <div className="contact-us-container-form-input">
              <label htmlFor="companyMail">Company Mail ID</label>
              <input
                type="email"
                id="companyMail"
                name="companyMail"
                value={formData.companyMail}
                onChange={handleInputChange}
              />
            </div>
            <div className="contact-us-container-form-input">
              <label htmlFor="password">New Password</label>
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                required
              />
              <span
                onClick={handleTogglePasswordVisibility}
                className="input-sign-up-eye-icon"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </span>
            </div>
            <div className="contact-us-container-form-input">
              <label htmlFor="confirmPassword">Re-enter Password</label>
              <input
                type={showConfirmPassword ? "text" : "password"}
                id="confirmPassword"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                required
              />
              <span
                onClick={handleToggleConfirmPasswordVisibility}
                className="input-sign-up-eye-icon"
              >
                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
              </span>
            </div>
            {errorMessage && (
              <div className="error-message" style={{ color: "red" }}>
                {errorMessage}
              </div>
            )}{" "}
            <div className="recaptcha-wrapper">
              <ReCAPTCHA
                sitekey={RECAPTCHA_SITE_KEY}
                onChange={onRecaptchaChange}
              />
            </div>
            <button type="submit">{loader ? "Loading..." : "Submit"}</button>
            <label className="pt-3 pb-3">
              By clicking submit application, You understand and agree to the
              terms of use and <a href="/privacy-policy">privacy policy</a>.
            </label>
          </form>
        </div>
      </div>
    </Box>
  );
};

export default SignUp;
