import React from "react";
import Header from "../../../components/Header/Header";
import Insightimg from "../../../img/InsightTwo.svg";
import Footer from "../../../components/Footer/Footer";
const Insight = () => {
  return (
    <div className="custom-managment-page">
      <Header />
      <div className="insight-container" style={{ paddingTop: "100px" }}>
        <img src={Insightimg} alt="Image" />
        <div className="insight-content">
          <header>
            <h2>Token Real Estate Booms, but For How Long? </h2>
            <div className="insight-content-author">
              <span>Siddhartha S.</span>
              <p>June 6, 2021</p>
            </div>
          </header>

          <section className="insight-sub-content">
            <p>
              With low-interest rates here to stay, pockets of suburbs and
              trendy exurbs have started to heat up the real estate market.
              Urbanites have been escaping the close confines of COVID infected
              cities to enjoy social distancing in the greener pastures of
              suburbia. As the U.S. economy grapples with a major recession and
              social unrest, real estate appears to be one of the best assets to
              survive the slowdown. 
            </p>
            <p>
              With mortgage interest rates on the downward trend, U.S. real home
              prices have risen 
              <a href="https://www.nytimes.com/2020/07/31/business/housing-market-prices-risk.html" target="_blank" rel="noopener noreferrer">
                45 percent from
              </a>{" "}
              February 2012 through May 2020 according to the
              <a href="https://www.spglobal.com/spdji/en/indices/indicators/sp-corelogic-case-shiller-us-national-home-price-nsa-index/#data" target="_blank" rel="noopener noreferrer">
                 S&P/CoreLogic/Case-Shiller National Home Price Index.
              </a>
              Considering the backdrop of the coronavirus pandemic, these trends
              definitely make real estate an enticing investment.
            </p>
            <p>
              But before we take out our wallets, Nobel laureate, Robert J.
              Shiller warns us of not letting history repeat itself. In a
              recent 
              <a href="https://www.nytimes.com/2020/07/31/business/housing-market-prices-risk.html">
                NYT Economic View
              </a>
               piece, Shiller noted, “It would be easy to assume that the boom
              times for housing will go on forever, but that would require
              ignoring the disaster that led to the most recent great financial
              crisis, a little more than a decade ago.”
            </p>
            <p>
              Let’s bring out the history book. From February 1997 to December
              2005, real home prices rose a staggering 
              <a href="https://www.nytimes.com/2020/07/31/business/housing-market-prices-risk.html" target="_blank" rel="noopener noreferrer">
                75 percent
              </a>
              &nbsp; . Seemingly unaffected by the stock market decline of 2000
              to 2002 andthe U.S. 2001 recession, investors flocked to the real
              estate market. But just as the delusions of an impenetrable
              mortgage-backed security spread, housing prices crashed
              <a href="https://www.nytimes.com/2020/07/31/business/housing-market-prices-risk.html" target="_blank" rel="noopener noreferrer">
                 36 percent
              </a>
              &nbsp;from 2005 to February 2012, plummeting the U.S. stock market
              with&nbsp;
              <a href="https://www.washingtonpost.com/business/economy/a-guide-to-the-financial-crisis--10-years-later/2018/09/10/114b76ba-af10-11e8-a20b-5f4f84429666_story.html" target="_blank" rel="noopener noreferrer">
                $8 trillion
              </a>
              &nbsp;lost between late 2007 and 2009. 
            </p>
            <p>
              The impact of the Great Recession highlighted the illiquidity of
              real estate assets. From slow transactions, settlement, and
              verification processes that include many intermediaries, to
              dispersed and isolated databases, the corporate real estate
              ecosystem revealed itself as an opaque, illiquid asset class.
            </p>
            <p>
              However, with the evolution of digital assets on the blockchain,
              real estate has become a forerunner in demonstrating the many
              benefits of tokenization. By unitizing real estate assets on the
              blockchain, the benefits of an immutable, transparent, and
              tamper-proof audit trail provide clearer asset valuations and
              better market efficiency. In April, these benefits came to
              fruition as real estate tokens increased at an average of 
              <a href="https://cointelegraph.com/news/tokenized-real-estate-booms-while-security-tokens-struggle">
                4.27 percent
              </a>
              , while top securities tokens suffered losses for the month. 
            </p>
            <p>
              There’s no doubt that tokenization can provide enhanced liquidity
              and democratizes investment opportunities, but we must recognize
              that it is only part of the solution. Currently, there exist no
              information rails that provide investors with insights to see the
              value of the underlying token asset in real-time. Unlike mutual
              funds or traded stocks, token assets have no NAV, 10K filings, or
              quarterly financials reported.
            </p>
            <p>
              With a mission to not repeat the same mistakes that led to the
              2008 financial crisis, Intain’s Asset Value Blockchain
              (AVB) addresses this knowledge gap by providing real-time
              asset-value insights to token holders. As real estate tokenization
              grows, it will be essential for investors to have a holistic
              insight into each token asset’s valuation. Intain’s AVB does just
              this by providing a token-specific ‘NAV’, giving real estate token
              holders the ability to track their token assets in real-time. 
            </p>
            <p>
              With this added layer of transparency, Intain’s AVB technology
              presents itself as an exciting development within the token real
              estate market. By combining the success of tokenization with the
              much needed token ‘NAV’, Intain’s AVB provides token holders with
              the liquidity that their digital securities are missing. 
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};
const InsightTwo = () => {
  return (
    <div>
      <Insight />
      <Footer />
    </div>
  );
};

export default InsightTwo;
