import React from "react";
import Header from "../../components/Header/Header";
import Events1 from "../../img/Events.svg";
import Events2 from "../../img/Events2.svg";
import Events3 from "../../img/Events3.svg";
import Events4 from "../../img/Events4.svg";
import Events5 from "../../img/Events5.svg";
import Events6 from "../../img/Events6.svg";
import Events7 from "../../img/Events7.svg";
import Events8 from "../../img/Events8.svg";
import Events9 from "../../img/Events9.svg";
import Events10 from "../../img/Events10.svg";
import Footer from "../../components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
const EventsData = () => {
  const navigate = useNavigate();
  const EventsItem = [
    {
      image: Events1,
      span: "Powering Business with Blockchain: Avalanche & Intain",
      label:
        "Coming up on the next Powering Business with Blockchain, Intain joins to talk about IntainMARKETS, a new tokenized marketplace for asset-backed securities, being built on Avalanche.",
      link: "March 13, 2023",
      navigateTo: "/powering-business-with-blockchain-avalanche-intain", // Add a dynamic route here
    },
    {
      image: Events2,
      span: "Powering Business with Blockchain: Avalanche & Intain",
      label:
        "Structured Finance Platform Intain Opens Tokenized Marketplace for Asset-Backed Securities.",
      link: "www.coindesk.com",
      navigateTo: "/sfa-vegas-2023", // Add a dynamic route here
    },
    {
      image: Events3,
      span: "SFA Vegas 2023 – Digitilization of Markets",
      label:
        "At #SFVegas2023 Intain CEO Siddhartha S would join a panel with leading financial institutions and technology companies to discuss the changes and challenges with respect to the digital future.",
      link: "February 10, 2023",
      navigateTo: "/sfa-vegas-2023-digitilization-of-markets", // Add a dynamic route here
    },
    {
      image: Events4,
      span: "SFA Vegas 2023 – 101: Blockchain",
      label:
        "Blockchain has an important role in the digitalization of markets, but it is only a part of the whole tech stack.",
      link: "February 10, 2023",
      navigateTo: "/sfa-vegas-2023-101-blockchain", // Add a dynamic route here
    },
    {
      image: Events5,
      span: "Blockchain Real Estate Summit 2022",
      label:
        "Our CEO Siddharth joins Avalanche AVAX President, John Wu for a fireside chat at the Blockchain Real Estate Summit to talk about future of MBS.",
      link: "September 21, 2022",
      navigateTo: "/blockchain-real-estate-summit-2022", // Add a dynamic route here
    },
    {
      image: Events6,
      span: "SFVegas 2022",
      label:
        "Intain Founder and CEO, Siddhartha, will be on the panel on Technology Trends in Securitization. Also, visit our booth to know more.",
      link: "May 23, 2022",
      navigateTo: "/sfvegas-2020", // Add a dynamic route here
    },
    {
      image: Events7,
      span: "LendIt FinTech",
      label:
        "Intain is excited to attend the conference and network with leading industry professionals and explore avenues and opportunities for ",
      link: "May 23, 2022",
      navigateTo: "/lendit-fintech", // Add a dynamic route here
    },
    {
      image: Events8,
      span: "Draper Security Token Summit",
      label:
        "If you have an interest in brining real world assets into DeFi, either as an issuer, investor, intermediary or venture investor, or just want to know about",
      link: "May 23, 2022",
      navigateTo: "/draper-security-token-summit", // Add a dynamic route here
    },
    {
      image: Events9,
      span: "ABS East 2021",
      label:
        "Join Intain at Information Management Network’s (IMN) 27th Annual ABS East Conference in Miami, Florida, for an opportunity to learn about the",
      link: "May 23, 2022",
      navigateTo: "/abs-east-2021", // Add a dynamic route here
    },
    {
      image: Events10,
      span: "Join Intain at RISE Conference, Hong Kong",
      label:
        "Intain will be at RISE Conference, Hong Kong from 8th-11th July, do come by to really understand the disruption we are trying to bring in your industry",
      link: "July 24, 2019",
      navigateTo: "/join-intain-at-rise-conference-hong-kong", // Add a dynamic route here
    },
  ];

  return (
    <Box
      className="custom-managment-page events-grid-container"
      
    >
      <div className="team-title platform">
        <h1>Events</h1>
        <span></span>
      </div>

      <div className="events-grid">
        {EventsItem.map((item, index) => (
          <div key={index} className="events-container">
            <img src={item.image} alt="news" />
            <span onClick={() => navigate(item.navigateTo)}>{item.span}</span>
            <label className="events-container-label">{item.label}</label>
            <div>{item.link}</div>
          </div>
        ))}
      </div>
    </Box>
  );
};

const News = () => {
  return (
    <div>
      <Header />
      <EventsData />
      <Footer />
    </div>
  );
};

export default News;
