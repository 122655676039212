import React from "react";
import Header from "../../../components/Header/Header";
import Insightimg from "../../../img/InsightSix.svg";
import highlevel from "../../../img/high-level.svg";
import capturevalue from "../../../img/capturevalue.svg";
import Footer from "../../../components/Footer/Footer";
const Insight = () => {
  return (
    <div className="custom-managment-page">
      <Header />
      <div className="insight-container" style={{ paddingTop: "100px" }}>
        <img src={Insightimg} alt="Image" />
        <div className="insight-content">
          <header>
            <h2>
              Capturing Value on a Permissioned Blockchain Trading its Value on
              a Public Blockchain{" "}
            </h2>
            {/* <h3>
              Among Plumbers and Punters in the Blockchain World, We are the
              Plumbers{" "}
            </h3> */}
            <span>Shruthi & Srihari</span>
            <div className="insight-content-date">October 14, 2019</div>
          </header>

          <section className="insight-sub-content">
            <p>
              VCs and Angel Investors invest in startups and SMEs, with a goal
              to achieve financial gains. The process of making an investment
              decision entails huge amount of auditing, scrutiny and
              verification due to the information asymmetry. This is a time
              consuming, resource intensive process with substantial transaction
              costs for both the investors seeking investment opportunities as
              well as startups/SME’s seeking fresh funds. Most of the startups
              have to deal with the following two challenges — (1) the early
              investors expect access to the latest information about the SME’s
              revenues and cash flows and liquidity/value unlocking on a
              continuous basis, (2) the private companies also have to deal with
              the challenge of authentic data and maintaining its
              confidentiality, leading to mistrust in the overall environment.
            </p>
            <p>
              The proposed blockchain solution here aims to solve the above two
              mentioned problems by using a combination of a permissioned as
              well as a permissionless blockchain platforms. The use of a
              permissioned blockchain for maintaining the SME operations will
              ensure the privacy and security of their individual financial
              details, as it will be accessible only to the permissioned users.
              The use of public blockchain for tokenization of the cash flows
              from the SME will allow the investors to movetheir assets more
              freely. Since, the issuance of tokens in the public blockchain
              will be directly proportional to the profits coming from the SME
              cash flows, the investors will have a clear view of the business
              operations to make better investment decisions. This will also
              make the system tamper proof, and prevent fraudulent practices.
              Moreover, the use of public blockchain enables investors across
              the globe to find and invest in an idea they find interesting,
              even before the company goes public.{" "}
            </p>

            <img src={capturevalue} alt="Image" className="capturevalue" />
          </section>
          <section className="insight-sub-content-1">
            <h2>Goal</h2>
            <p>
              This use case has two main objectives — (1) Maintaining the
              financial transactions and cash flows of the company on a private
              blockchain (Hyperledger Fabric), thus bringing transparency to the
              Investor for ease of investment decision making and ownership, (2)
              Issuing tokens to the Investors backed by the profits coming from
              the cash flows of the SME, using a public blockchain (Ethereum).
            </p>
          </section>
          <section className="insight-sub-content-1">
            <img
              src={highlevel}
              alt="Image"
             className="heigh-value"
            />

            <p>
              The high level functional approach for implementing this use is
              summarized below -The SME and it’s Investors’ profiles will be
              maintained on Hyperledger Fabric. For the initial setup, the
              company decides its initial token supply based on the cash flows
              and associated benchmark of a quarter which is stored in Fabric.
              The token issuance to the investors will be done based on their
              percentage shareholding on the Ethereum platform.
            </p>
            <p>
              Subsequently, at a regular frequency (on a quarterly basis), the
              SME will keep uploading it’s cash flow statement, and the Fabric
              platform will record the details and make updates to the
              benchmark. Based on the benchmark variation, the corresponding
              changes to the overall and investor specific tokens will be pushed
              to the Ethereum platform. The Ethereum platform will maintain the
              changes in the number of tokens of the individual token owners and
              maintain investor wallets.
            </p>
            <p>
              The investors can execute transactions (i.e., transfer, redemption
              of tokens, etc.) on the Ethereum platform. Token Economics At
              Token Issuance, the SME provides the initial cash flow of the
              quarter (CFi), along with the initial total supply of tokens (TSi)
              and the benchmark (BM) associated with it. The tokens are
              distributed to the Investors (incl. founders) proportional to
              their existing equity % in the company.
            </p>
            <p>
              Every quarter, the SME will upload the cash flow document and the
              benchmark will be recalculated as follows -BM(new) =
              (BM(initial)/CFi) * (Cash flow of the current quarter)% variation
              in Benchmark = ((BM(new) — BM(initial))/BM(initial)) * 100Based on
              the % variation in Benchmark, tokens will be burnt as per the
              following rules -If the benchmark variation is negative or is less
              than 50%, no tokens will be burnt.
            </p>
            <p>
              If the benchmark variation is between 50% and 100%, 5% of the
              Initial Token Supply (TSi) of the tokens will be burnt. If the
              benchmark variation is &gt; 100%, 10% of the TSi of tokens will be
              burnt. The tokens will be burnt, till the outstanding tokens will
              be 50% of TSi. When the SME goes public or is acquired, all the
              outstanding tokens will be burnt. The SME specific tokens can be
              exchanged against an exchange-traded platform-specific security
              token (To be developed) and hence traded, leading to liquidity for
              the token owners.
            </p>
          </section>
          <section className="insight-sub-content-1">
            <h2>Technical Architecture</h2>
            <p>
              The architecture of the platform can be defined in three layers
              (as shown above) –The Blockchain Network layer –This layer
              consists of two blockchain networks -Hyperledger Fabric network
              (permissioned blockchain)-It is setup with 2 Orgs, and 4 peers
              nodes (2 peers in each Org). Each Org represents an SME.
            </p>
            <p>
              Fabric CA is used for Identity Management. An SME user will be
              registered and enrolled in Fabric CA. This SME user can then add
              it’s Investor users in the system by registering and enrolling
              them in Fabric CA. There are two Fabric CAs configured for this
              platform, one associated with each Org.
            </p>
            <p>
              Chaincodes are written in Golang for saving the SME cash flow
              document, and the benchmark variation details, along with other
              SME and Investor profile details. These chaincodes are installed
              and instantiated on all the peer nodes.2. Ethereum network (public
              blockchain) -The SME and Investor wallets are created and
              maintained on the Ethereum Ropsten Network.
            </p>
            <p>
              A Smart Contract written in Solidity and deployed on Ethereum
              contains the logic for token creation, issuance and distribution
              to Investors. The smart contract is based on the functionalities
              derived from ERC20, and will be extended to ERC777 and ERC1400
              standards in future.
            </p>
            <p>
              {" "}
              The ERC20 standard includes the following functions -The
              additional functionalities of burn and minting tokens are
              implemented as follows –
            </p>
            <p>
              1 . The wallet addresses of the users and contract address of the
              token are stored in Fabric for reference and authentication
              purposes. The interaction between the two blockchains is achieved
              through RESTful web APIs that contain the business logic of the
              application.
            </p>
            <p>
              2 . The Business Logic(web app) Middle Layer –This layer contains
              the web application code written in a RESTful microservices based
              architecture. The application is built with two microservices as
              follows -The microservice for Capturing the Company Value on
              Fabric -This web application is built using Nodejs. It uses the
              fabric-node-SDK based REST APIs to communicate with the Fabric
              blockchain.
            </p>
            <p>
              The fabric-client based APIs are used to register and enroll users
              in Fabric CA. Every time a user logs into the system, the
              fabric-client is used to check if the user is already registered
              and enrolled. On successful authentication, a JWT token is
              generated for the user that stays valid till the user session
              expires. This token is used as an authentication token for
              accessing any functionalities built upon Fabric.
            </p>
            <p>
              This microservice contains functionalities for maintaining the SME
              and Investor Profiles in Fabric. Apart from this, it contains the
              main logic for maintaining the initial and periodic benchmark
              variation details, along with the details of the uploaded cashflow
              document in each quarter.
            </p>
            <p>
              Cash flow Document Upload and Value Capture API – The SME user
              will upload the cash flow document for each quarter on the
              platform. For the initial setup, the initial benchmark will be
              taken as input from the user and stored in fabric along with other
              details from the document. The number of tokens to be allocated to
              each investor will be calculated and sent to the Ethereum API for
              transferring the tokens to individual investor wallets.
            </p>
            <p>
              In subsequent quarters, a scheduler will read the contents of the
              document uploaded by the SME, will calculate the new benchmark( as
              described in the section Token Economics), and will store the
              details along with the document hash and it’s storage location in
              Fabric.
            </p>
            <p>
              Based on the new benchmark, the number of tokens to be burnt are
              calculated for each investor and sent to the Ethereum APIs, along
              with the investors’ wallet addresses, where the tokens are burnt
              from each investor’s wallet.
            </p>
            <p>
              <b>Reference:</b> Based on paper presented at Hyperledger Member
              Summit, Tokyo 2019
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};
const InsightSix = () => {
  return (
    <div>
      <Insight />
      <Footer />
    </div>
  );
};

export default InsightSix;
