import React from "react";
import intainadmin from "../../img/intainadmin.svg";
import indai from "../../img/in-d-logo1-1.png";
import intainmarkets from "../../img/intainmarkets.svg";
import { Box } from "@mui/material";
const Platform = () => {
  return (
    <div className="green-page platform-container">
      <div className="platform">
        <div>Intain Platforms</div>
        <span></span>
      </div>
      <Box class="d-flex gap-3">
        <div className="intainadmin">
          <div class="d-flex gap-3">
            <img alt="intainadmin" src={intainadmin} />
            <label>
              INTAIN<span>ADMIN</span>
            </label>
          </div>
          <p>
            Integrated and automated administration of structured finance
            across parties
          </p>
        </div>
        <div className="intainadmin">
          <div class="d-flex gap-3">
            <img
              alt="intainAI"
              src={indai}
              style={{ width: "49px", height: "78px" }}
            />
            <label>
              INTAIN<span>AI</span>
            </label>
          </div>
          <p>
            AI-Powered document processing 
            for asset-backed finance{" "}
          </p>
        </div>
      </Box>
      <Box className="intainmarket">
        <div class="d-flex gap-3">
          <img alt="" src={intainmarkets} />
          <label>
            INTAIN<span>MARKETS</span>
          </label>
        </div>
        <p>
          For trust, transparency and efficiency in structured 
          credit markets
        </p>
      </Box>
    </div>
  );
};

export default Platform;
