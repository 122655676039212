import React from "react";
import InsightsandProduct from "./InsightsandProduct";

const Resource = () => {
  return (
    <div>
      <InsightsandProduct />
    </div>
  );
};

export default Resource;
