import React, { useEffect, useRef, useState } from "react";
import { Dialog, Box } from "@mui/material";
import InsightOneImg from "../../img/InsightOne.svg";
import InsightBox1 from "../../img/Insight-box1.svg";
import InsightBox2 from "../../img/Insight-box2.svg";
import InsightBox3 from "../../img/Insight-box3.svg";
import InsightBox4 from "../../img/Insight-box4.svg";
import InsightBox5 from "../../img/Insight-box5.svg";
import InsightBox6 from "../../img/Insight-box6.svg";
import medium from "../../img/medium.svg";
import mediumicon from "../../img/mediumicon.svg";
import mediumlighticon from "../../img/medium-light.svg";
import mail from "../../img/mail.svg";
import Date from "../../img/Date.svg";
import { Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Close } from "@mui/icons-material";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { productsubscribe } from "../../Actions/Action";

const insightsData = [
  {
    title: "Token Real Estate Booms, but...",
    text: "As the U.S. economy grapples with a major recession and social unrest, real estate appears to remain the best…",
    date: "August 25, 2020",
    image: InsightBox1,
    className: "insight-box-text ",
    navigateTo: "/token-real-estate-booms-but-for-how-long-2", // Add a dynamic route here
  },
  {
    title: "Prime Time for Invoice Factoring",
    text: "Invoice factoring has seen much innovation in recent years, but Intain believes that future opportun…",
    date: "July 09, 2020",
    image: InsightBox2,
    className: "insight-box-text insight-dyn-padd",
    navigateTo: "/prime-time-for-invoice-factoring", // Add a dynamic route here
  },

  {
    title: "Distributed Ledger Technology...",
    text: "Like many people a few years ago,I had been hearing about cryptocurrency for some time,",
    date: "November 02, 2019",
    image: InsightBox3,
    className: "insight-box-text insight-dyn-padd-1 insight-box-text-dyn",
    navigateTo:
      "/distributed-ledger-technology-and-the-future-of-securitization", // Add a dynamic route here
  },
  {
    title: "The Boring Blockchain Initiative",
    text: "Among Plumbers and Punters in the Blockchain World, We are the Plumbers…",
    date: "October 15, 2019",
    image: InsightBox4,
    className: "insight-box-text insight-dyn-padd-1 insight-box-text-dyn",
    navigateTo: "/the-boring-blockchain-initiative", // Add a dynamic route here
  },
  {
    title: "Capturing Value on a Permissioned...",
    text: "VCs and Angel investors invest in startups and SMEs, with a goal to achieve financial gains. The pro…",
    date: "October 14, 2019",
    image: InsightBox5,
    className:
      "insight-box-text insight-dyn-padd-2 insight-box-text-dyn-1 insight-box-text-dyn-1",
    navigateTo:
      "/capturing-value-on-a-permissioned-blockchain-trading-its-value-on-a-public-blockchain", // Add a dynamic route here
  },
  {
    title: "Intelligent Blockchain for Asset Sec...",
    text: "Enhanced Liquidity and Reduced Risk in Securitisation to address the crisis with Non Banking Finance Comp…",
    date: "June 26, 2019",
    image: InsightBox6,
    className: "insight-box-text insight-dyn-padd-3",
    navigateTo: "/intelligent-blockchain-for-asset-securitisation", // Add a dynamic route here
  },
];
const insights = [
  {
    image: medium,
    text: "Integrated Data Operation and Process Automation for Structured Finance with IntainADMIN v2.0",
    icon: mediumicon,
    light: mediumlighticon,
    // style: {
    //   width: "60px",
    //   height: "60px",
    //   position: "relative",
    //   left: "10rem",
    //   top: "0rem",
    // },
    blogLink:
      "https://medium.com/intain/integrated-data-operations-and-process-automation-for-structured-finance-with-intainadmin-v2-0-d786df0a17a7", // External link
  },
  {
    image: medium,
    text: "Builder Spotlight: Intain Brings Securitized Finance to a Subnet",
    icon: mediumicon,
    light: mediumlighticon,

    // style: {
    //   width: "60px",
    //   height: "60px",
    //   position: "relative",
    //   left: "10rem",
    //   top: "1rem",
    // },
    blogLink:
      "https://medium.com/avalancheavax/builder-spotlight-intain-brings-securitized-finance-to-a-subnet-ccc27d8286f8",
  },
  {
    image: medium,
    text: "Intain Launches Avalanche Subnet to Usher in New Era for Multi-Trillion Dollar Securitized Finance Market",
    icon: mediumicon,
    light: mediumlighticon,

    // style: {
    //   width: "60px",
    //   height: "60px",
    //   position: "relative",
    //   left: "10rem",
    //   // bottom: "20px",
    //   top: "0rem",
    // },
    blogLink:
      "https://medium.com/avalancheavax/intain-launches-avalanche-subnet-to-usher-in-new-era-for-multi-trillion-dollar-securitized-877c7cc1031f",
  },
  {
    image: medium,
    text: "Traditional Financial Investments on Blockchain — The Settlement Challenge",
    icon: mediumicon,
    light: mediumlighticon,

    // style: {
    //   width: "60px",
    //   height: "60px",
    //   position: "relative",
    //   left: "10rem",
    //   top: "0rem",
    // },
    blogLink:
      "https://medium.com/intain/traditional-financial-investments-on-blockchain-the-settlement-challenge-6b3113d256bb",
  },
  {
    image: medium,
    text: "How IntainMARKETS addressed Institutional concerns regarding the Blockchain ecosystem?",
    icon: mediumicon,
    light: mediumlighticon,

    // style: {
    //   width: "60px",
    //   height: "60px",
    //   position: "relative",
    //   left: "10rem",
    //   top: "0rem",
    // },
    blogLink:
      "https://medium.com/intain/how-intainmarkets-addressed-institutional-concerns-regarding-the-blockchain-ecosystem-73772fb738b0",
  },
];

const InsightOne = () => {
  const navigate = useNavigate();
  const handleInsightOne = () => {
    navigate("/intain-defi-transparency-trust-and-liquidity-in-that-order");
  };
  return (
    <div className="insight-one-container insight-one">
      <img src={InsightOneImg} alt="insight-image" />
      <div className="insight-one-text">
        <a onClick={handleInsightOne}>
          <label>
            Intain DeFi — Transparency, Trust and Liquidity — In that Order
          </label>
        </a>

        <p>
          <img src={Date} alt="insight-date" /> June 6, 2021
        </p>
        <span>
          In Capital Markets, DeFi is about tokenized assets and securities, but
          it does not seem to be working.
        </span>
      </div>
    </div>
  );
};

const InsightTwo = () => {
  const navigate = useNavigate();
  return (
    <Box className="insight-two">
      {insightsData.map((insight, index) => (
        <div className="insight-box" key={index}>
          <img src={insight.image} alt="Insight Image" />
          <div
            onClick={() => navigate(insight.navigateTo)}
            className={insight.className}
          >
            {insight.title}
            <span>{insight.text}</span>
            <p>{insight.date}</p>
          </div>
        </div>
      ))}
    </Box>
  );
};

const InsightThree = () => {
  const [isDarkMode, setIsDarkMode] = useState(
    sessionStorage.getItem("dark-theme") === "dark"
  );

  // working
  useEffect(() => {
    const checkTheme = () => {
      const darkTheme = sessionStorage.getItem("dark-theme") === "dark";
      setIsDarkMode(darkTheme);
    };

    // Directly listen for manual theme change events (if user toggles dark mode within same tab)
    window.addEventListener("storage", checkTheme);

    // Optional: If you have a theme toggle button elsewhere, you can listen to it
    const interval = setInterval(checkTheme, 10); // Checking every half second if the theme changes

    return () => {
      window.removeEventListener("storage", checkTheme);
      clearInterval(interval); // Clean up the interval on component unmount
    };
  }, []);

  const handleClick = (insight) => {
    window.open(insight.blogLink, "_blank"); // Opens the link in a new tab
  };
  return (
    <div className="green-page insight-width">
      <Box className="insight-three-container">
        {insights.map((insight, index) => (
          <div
            onClick={() => handleClick(insight)}
            className="insight-three"
            key={index}
          >
            <img src={insight.image} alt="Insight Image" />
            <div className="insight-three-text">{insight.text}</div>
            <img
              src={isDarkMode ? insight.icon : insight.light}
              alt="Insight Icon"
              style={insight.style}
              className="mediumicon"
            />
          </div>
        ))}
      </Box>
    </div>
  );
};

const InsightFour = ({ scrollToFooterInput }) => {
  return (
    <Box className="insight-four-container">
      <div className="updatebox">
        <img src={mail} alt="Image" />
        <div>
          Stay up to Date
          <br />
          <p>
            Your email will be used to send you our new blog post and updates.
            You can unsubscribe at any time using the link in our emails.
          </p>
        </div>
        <div>
          <Button variant="contained" onClick={scrollToFooterInput}>
            Subscribe
          </Button>
        </div>
      </div>
    </Box>
  );
};

const InsightFive = () => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    jobTitle: "",
    company: "",
    role: "",
    phone: "",
    email: "",
    password: "",
    confirmPassword: "",
    contactConsultant: "",
    securitization: [],
    receiveUpdates: "",
    termsAccepted: false,
  });

  const [page, setPage] = useState(1); // To control which page of the form is shown
  const [loader, setLoader] = useState(false);

  const allFieldsFilled = () => {
    return Object.values(formData).every((field) => {
      // Check for arrays separately (like securitization)
      if (Array.isArray(field)) return field.length > 0;
      return field !== "";
    });
  };
  const closeDeal = () => {
    setDialogOpen(false);
    setFormData({
      firstName: "",
      lastName: "",
      jobTitle: "",
      company: "",
      role: "",
      phone: "",
      email: "",
      password: "",
      confirmPassword: "",
      contactConsultant: "",
      securitization: [],
      receiveUpdates: "",
    });
  };
  const handleNextPage = () => {
    setPage(2);
  };

  const handlePreviousPage = () => {
    setPage(1);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      // Handle checkbox for securitization array
      if (name === "securitization") {
        setFormData((prevData) => {
          const updatedSecuritization = checked
            ? [...prevData.securitization, value]
            : prevData.securitization.filter((item) => item !== value);
          return { ...prevData, securitization: updatedSecuritization };
        });
      } else {
        // Handle terms checkbox
        setFormData((prevData) => ({ ...prevData, [name]: checked }));
      }
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    await productsubscribe(formData);
    setLoader(false);

    setDialogOpen(false);
    setFormData({
      firstName: "",
      lastName: "",
      jobTitle: "",
      company: "",
      role: "",
      phone: "",
      email: "",
      password: "",
      confirmPassword: "",
      contactConsultant: "",
      securitization: [],
      receiveUpdates: "",
      termsAccepted: false,
    });

    console.log("Form Data Submitted:", formData);
  };

  return (
    <div className="productrelease">
      <div className="productrelease-content">
        Intain’s platform provides a token-specific ‘NAV’, giving token holders
        the ability to track their token assets in real-time. With Intain AVB,
        investors have a holistic insight into each token asset's valuation,
        providing clearer asset valuations and better market efficiency.
      </div>
      <div className="productrelease-btn">
        <Button variant="contained" onClick={() => setDialogOpen(true)}>
          Register
        </Button>
      </div>
      <>
        <Dialog
          onClose={closeDeal}
          aria-labelledby="customized-dialog-title"
          open={dialogOpen}
          sx={{
            "& .MuiDialog-paper": {
              width: "700px",
              maxWidth: "100%",
              borderRadius: "10px",
              padding: "40px",
              height: "820px",
              background: "var(--bgcolor)",
          
              // Media queries using MUI breakpoints
              "@media (max-width: 1024px)": {
                width: "600px",
                padding: "30px",
                height: "700px",
              },
              "@media (max-width: 768px)": {
                width: "90%",
                padding: "20px",
                height: "auto",
              },
              "@media (max-width: 480px)": {
                width: "100%",
                padding: "15px",
                borderRadius: "5px",
                height: "auto",
              },
            },
          }}
          
        >
          <div>
            <form
              className="product-form custom-managment-form"
              onSubmit={handleSubmit}
            >
              <div className="closeicon">
                <Close onClick={closeDeal} className="icon"></Close>
              </div>
              <h2>Product Subscription!</h2>
              <p>
                Please provide your name, title, company, role, and contact
                information. *
              </p>
              {page === 1 && (
                <>
                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="firstName">First Name</label>
                      <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        placeholder="First Name"
                        className="custom-input"
                        value={formData.firstName}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="lastName">Last Name</label>
                      <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        placeholder="Last Name"
                        className="custom-input"
                        value={formData.lastName}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="jobTitle">Job Title</label>
                      <input
                        type="text"
                        id="jobTitle"
                        name="jobTitle"
                        placeholder="Job Title"
                        className="custom-input"
                        value={formData.jobTitle}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="company">Company</label>
                      <input
                        type="text"
                        id="company"
                        name="company"
                        placeholder="Company"
                        className="custom-input"
                        value={formData.company}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="role">Role</label>
                      <select
                        id="role"
                        name="role"
                        className="custom-select"
                        value={formData.role}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select Role</option>
                        <option value="Admin">Admin</option>
                        <option value="User">User</option>
                        <option value="Manager">Manager</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label htmlFor="phone">Phone</label>
                      <input
                        type="tel"
                        id="phone"
                        name="phone"
                        placeholder="Phone"
                        className="custom-input"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                        style={{ height: "50px" }}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email Address</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email Address"
                      className="custom-input"
                      value={formData.email}
                      onChange={handleChange}
                      required
                      style={{
                        height: "50px",
                        borderRadius: "5px",
                        width: "101%",
                      }}
                    />
                  </div>
                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="password">Password</label>
                      <input
                        type="password"
                        id="password"
                        name="password"
                        placeholder="Password"
                        className="custom-input"
                        value={formData.password}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="confirmPassword">Re-Enter Password</label>
                      <input
                        type="password"
                        id="confirmPassword"
                        name="confirmPassword"
                        placeholder="Re-Enter Password"
                        className="custom-input"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <p style={{ marginTop: "10px" }}>
                      Would you like to be contacted by one of our consultants
                      to discuss Intain's platform?
                    </p>
                    <div className="radio-group">
                      <input
                        type="radio"
                        id="yes"
                        name="contactConsultant"
                        value="yes"
                        checked={formData.contactConsultant === "yes"}
                        onChange={handleChange}
                        style={{ display: "none" }} // Hide the default radio button
                      />
                      <label
                        htmlFor="yes"
                        className="custom-radio"
                        style={{ marginRight: "10px" }}
                      >
                        Yes
                      </label>

                      <input
                        type="radio"
                        id="no"
                        name="contactConsultant"
                        value="no"
                        checked={formData.contactConsultant === "no"}
                        onChange={handleChange}
                        style={{ display: "none" }} // Hide the default radio button
                      />
                      <label htmlFor="no" className="custom-radio">
                        No
                      </label>
                    </div>
                  </div>
                  <div className="form-buttons">
                    <button
                      type="button"
                      className="next-button"
                      onClick={handleNextPage}
                    >
                      Next
                    </button>
                    <div className="divider"></div>
                    <button
                      type="submit"
                      className="submit-button"
                      disabled={!allFieldsFilled()}
                    >
                      {loader ? "Loading..." : "Submit"}
                    </button>
                  </div>
                </>
              )}

              {page === 2 && (
                <>
                  <div className="form-group">
                    <p>
                      What areas of securitization do you cover? (Select all
                      which are relevant)
                    </p>
                    {[
                      "ABS",
                      "RMBS",
                      "CMBS",
                      "CLOs",
                      "Other Securitization",
                      "Digital Assets",
                    ].map((sec) => (
                      <div key={sec}>
                        <input
                          type="checkbox"
                          id={sec.toLowerCase()}
                          name="securitization"
                          value={sec}
                          checked={formData.securitization.includes(sec)}
                          onChange={handleChange}
                          className="large-checkbox"
                        />
                        <label htmlFor={sec.toLowerCase()}>{sec}</label>
                        <br />
                      </div>
                    ))}
                  </div>
                  <div className="form-group">
                    <p>
                      I'd like to receive exclusive insights and product updates
                      from Intain
                    </p>
                    <div className="radio-group">
                      <input
                        type="radio"
                        id="updatesYes"
                        name="receiveUpdates"
                        value="yes"
                        checked={formData.receiveUpdates === "yes"}
                        onChange={handleChange}
                        style={{ display: "none" }} // Hide the default radio button
                      />
                      <label
                        className="custom-radio"
                        htmlFor="updatesYes"
                        style={{ marginRight: "5px" }}
                      >
                        Yes
                      </label>

                      <input
                        type="radio"
                        id="updatesNo"
                        name="receiveUpdates"
                        value="no"
                        checked={formData.receiveUpdates === "no"}
                        onChange={handleChange}
                        style={{ display: "none" }} // Hide the default radio button
                      />
                      <label className="custom-radio" htmlFor="updatesNo">
                        No
                      </label>
                    </div>
                  </div>

                  <p>
                    You can unsubscribe at any time by clicking the unsubscribe
                    link at the bottom of any communications we send you. You
                    can also visit our preference centre.
                  </p>

                  <div className="form-group">
                    <input
                      type="checkbox"
                      id="terms"
                      name="termsAccepted"
                      required
                      checked={formData.termsAccepted}
                      onChange={handleChange}
                      className="large-checkbox"
                    />
                    <label htmlFor="terms">
                      I have read and agreed to the Terms & Conditions *
                    </label>
                  </div>

                  <div className="form-buttons">
                    <button
                      type="button"
                      className="previous-button"
                      onClick={handlePreviousPage}
                    >
                      Previous
                    </button>
                    <div className="divider"></div>
                    <button
                      type="submit"
                      className="submit-button"
                      disabled={!allFieldsFilled()}
                    >
                      {loader ? "Loading..." : "Submit"}
                    </button>
                  </div>
                </>
              )}
            </form>
          </div>
        </Dialog>
      </>
    </div>
  );
};

const InsightsandProduct = () => {
  const [isInsightsInView, setIsInsightsInView] = useState(false);
  const [isProductInView, setIsProductInView] = useState(false);

  const location = useLocation();
  const insightref = useRef(null); // Ref for the Insights section
  const productref = useRef(null); // Ref for the Product Release section
  const footerInputRef = useRef(null); // Ref for Footer's input field
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const insightscroll = queryParams.get("section") === "insights";
    const productrelease = queryParams.get("section") === "products";

    if (insightscroll && insightref.current) {
      insightref.current.scrollIntoView({ behavior: "smooth" });
    }
    if (productrelease && productref.current) {
      productref.current.scrollIntoView({ behavior: "smooth" }); // This was incorrectly using insightref
    }
    const insightObserver = new IntersectionObserver(
      ([entry]) => {
        setIsInsightsInView(entry.isIntersecting);
      },
      {
        threshold: 0.1, // Adjust threshold for when to trigger
      }
    );

    const productObserver = new IntersectionObserver(
      ([entry]) => {
        setIsProductInView(entry.isIntersecting);
      },
      {
        threshold: 0.1, // Adjust threshold for when to trigger
      }
    );

    if (insightref.current) {
      insightObserver.observe(insightref.current);
    }

    if (productref.current) {
      productObserver.observe(productref.current);
    }

    return () => {
      if (insightref.current) {
        insightObserver.unobserve(insightref.current);
      }
      if (productref.current) {
        productObserver.unobserve(productref.current);
      }
    };
  }, [location]);

  // Update scrollTo to accept a ref
  const scrollTo = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const div = document.querySelector(".centered-div");
      if (window.scrollY > 50) {
        div.classList.add("scrolled");
      } else {
        div.classList.remove("scrolled");
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToFooterInput = () => {
    if (footerInputRef.current) {
      footerInputRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="custom-managment-page">
      <Header />
      <div className="centered-div">
        <button
          className={isInsightsInView ? "underlined" : ""}
          onClick={() => scrollTo(insightref)}
        >
          Insights
        </button>
        <button
          className={isProductInView ? "underlined" : ""}
          onClick={() => scrollTo(productref)}
        >
          Product Release
        </button>
      </div>
      <div ref={insightref} className="team-title platform position-relative">
        <h1>Insights</h1>
        <span></span>
      </div>
      <InsightOne />
      <InsightTwo />
      <InsightThree />
      <InsightFour scrollToFooterInput={scrollToFooterInput} />
      <div className="">
        <div
          ref={productref}
          className="team-title platform"
          style={{ marginTop: "100px" }}
        >
          <h1>Product Releases</h1>
          <span></span>
        </div>
        <InsightFive />
      </div>
      <Footer footerInputRef={footerInputRef} />
    </div>
  );
};

export default InsightsandProduct;
