import React from "react";
const LinkedIn = () => {
  return (
    <div className="linkediin">
      <iframe
        title="LinkedIn"
        src="https://widgets.sociablekit.com/linkedin-page-posts/iframe/130929"
        frameborder="0"
      ></iframe>
    </div>
  );
};

export default LinkedIn;
