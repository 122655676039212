import React, { useState } from "react";
import intainlogo from "../../img/intainlogo-3.png";
import { Dialog, Box } from "@mui/material";
import { Close, Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { signin } from "../../Actions/Action";
const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [loader, setLoader] = useState(false);
  const closeDeal = () => {
    setDialogOpen(false);
  };

  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    const data = {
      email: email,
      password: password,
    };

    await signin(data);
    setLoader(false);
    navigate("/");
  };

  return (
    <div className="custom-managment-page">
      <div className="login-page">
        <img src={intainlogo} alt="Logo" onClick={() => navigate("/")} />
        <div className="login-container">
          <p>Welcome to Intain</p>
          <form
            onSubmit={handleSubmit}
            style={{ maxWidth: "575px", margin: "auto" }}
          >
            <div className="login-form">
              <label htmlFor="email">
                Email&nbsp;
                <span className="asterisk">*</span> {/* Change color here */}
              </label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="login-input"
              />
            </div>
            <div className="login-form" style={{ marginBottom: "-20px" }}>
              <label htmlFor="password">
                Password&nbsp;
                <span className="asterisk">*</span>
              </label>
              <div className="input-flex">
                <input
                  id="password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="login-input"
                />
                <span
                  onClick={handleTogglePasswordVisibility}
                  className="input-eye-icon"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </span>
              </div>
            </div>
            <button type="submit">{loader ? "Loading..." : "Sign In"}</button>
            <div className="login-form-link">
              <a href="/sign-up">Don't have an account? Sign Up</a>
              <a
                onClick={() => setDialogOpen(true)}
                //   href="/forgot-password"
                style={{ cursor: "pointer" }}
              >
                Forgot password?
              </a>
            </div>

            <div className="privacy-policy">
              By using this website, I agree that I have read and understood the
              terms of use and accepted Intain's{" "}
              <a href="/privacy-policy">privacy policy</a>.
            </div>
            <div className="privacy-policy">
              If you need any help signing in or have any questions, please feel
              free to contact us at &nbsp;
              <a
                href="https://mail.google.com/mail/?view=cm&fs=1&to=explore@intainft.com"
                target="_blank"
              >
                explore@intainft.com
              </a>
            </div>
          </form>
        </div>
        <>
          <Dialog
            onClose={closeDeal}
            aria-labelledby="customized-dialog-title"
            open={dialogOpen}
          >
            <Box className=" demo-container custom-managment-form">
              <div className="closeicon">
                <Close onClick={closeDeal} className="icon"></Close>
              </div>

              <div className="forgot-password">
                <h2>Forgot password?</h2>
                <p>
                  Contact your Customer Service Manager via{" "}
                  <a
                    style={{ cursor: "pointer" }}
                    href="https://mail.google.com/mail/?view=cm&fs=1&to=explore@intainft.com"
                    target="_blank"
                  >
                    email
                  </a>{" "}
                  to update your password.
                </p>
                <button type="submit">Done</button>
              </div>
            </Box>
          </Dialog>
        </>
      </div>
    </div>
  );
};

export default Login;
