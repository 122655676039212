import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

import HomepageStatic from "./screens/HomepageStatic/HomepageStatic";
import IntainAdmin from "./screens/IntainAdmin/IntainAdmin";
import IntainMarket from "./screens/IntainMarkets/IntainMarket";
import Company from "./screens/Company/Company";
import Resource from "./screens/Resource/Resource";
import Contactus from "./screens/ContactUs/Contactus";
import News from "./screens/News/News";
import Events from "./screens/Events/Events";
import ScrollToTop from "../src/components/ScrolltoTop/ScrollToTop"; // Import ScrollToTop component
import "./App.css";
import InsightOne from "./screens/Resource/InsightScreens/InsightOne";
import InsightTwo from "./screens/Resource/InsightScreens/InsightTwo";
import InsighThree from "./screens/Resource/InsightScreens/InsightThree";
import InsightFour from "./screens/Resource/InsightScreens/InsightFour";
import InsightFive from "./screens/Resource/InsightScreens/InsightFive";
import InsightSix from "./screens/Resource/InsightScreens/InsightSix";
import InsightSeven from "./screens/Resource/InsightScreens/InsightSeven";
import Events1 from "./screens/Resource/EventsScreens/Events";
import Events2 from "./screens/Resource/EventsScreens/EventsTwo";
import Events3 from "./screens/Resource/EventsScreens/EventsThree";
import Events4 from "./screens/Resource/EventsScreens/EventsFour";
import Events5 from "./screens/Resource/EventsScreens/EventsFive";
import Events6 from "./screens/Resource/EventsScreens/EventsSix";
import Events7 from "./screens/Resource/EventsScreens/EventsSeven";
import Events8 from "./screens/Resource/EventsScreens/EventsEight";
import Events9 from "./screens/Resource/EventsScreens/EventsNine";
import Events10 from "./screens/Resource/EventsScreens/EventsTen";
import Login from "./screens/Login/Login";
import PrivacyPolicy from "./screens/Privacy Policy/privacypolicy";
import TermsandCondition from "./screens/TermsandConditions/TermsandCondition";
import SignUp from "./screens/Login/SignUp";
import { Toaster } from "react-hot-toast";

export const App = () => {
  return (
    <>
      {/* <RouterProvider router={router}>
        <ScrollToTop />
      </RouterProvider> */}
      <Router>
        <Toaster position="top-right" />
        <ScrollToTop /> {/* Now inside Router */}
        <Routes>
          <Route path="/" element={<HomepageStatic />} />
          <Route path="/signin" element={<Login />} />
          <Route path="/intain-admin" element={<IntainAdmin />} />
          <Route path="/intain-markets" element={<IntainMarket />} />
          <Route path="/company" element={<Company />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/contact-us" element={<Contactus />} />
          <Route path="/news" element={<News />} />
          <Route path="/events" element={<Events />} />

          {/* Protected Routess */}
          <Route path="/resource" element={<Resource />} />
          <Route
            path="/intain-defi-transparency-trust-and-liquidity-in-that-order"
            element={<InsightOne />}
          />

          <Route
            path="/token-real-estate-booms-but-for-how-long-2"
            element={<InsightTwo />}
          />
          <Route
            path="/prime-time-for-invoice-factoring"
            element={<InsighThree />}
          />
          <Route
            path="/distributed-ledger-technology-and-the-future-of-securitization"
            element={<InsightFour />}
          />
          <Route
            path="/the-boring-blockchain-initiative"
            element={<InsightFive />}
          />
          <Route
            path="/capturing-value-on-a-permissioned-blockchain-trading-its-value-on-a-public-blockchain"
            element={<InsightSix />}
          />
          <Route
            path="/intelligent-blockchain-for-asset-securitisation"
            element={<InsightSeven />}
          />
          <Route
            path="/powering-business-with-blockchain-avalanche-intain"
            element={<Events1 />}
          />
          <Route path="/sfa-vegas-2023" element={<Events2 />} />
          <Route
            path="/sfa-vegas-2023-digitilization-of-markets"
            element={<Events3 />}
          />
          <Route path="/sfa-vegas-2023-101-blockchain" element={<Events4 />} />
          <Route
            path="/blockchain-real-estate-summit-2022"
            element={<Events5 />}
          />
          <Route path="/sfvegas-2020" element={<Events6 />} />
          <Route path="/lendit-fintech" element={<Events7 />} />
          <Route path="/draper-security-token-summit" element={<Events8 />} />
          <Route path="/abs-east-2021" element={<Events9 />} />
          <Route
            path="/join-intain-at-rise-conference-hong-kong"
            element={<Events10 />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/term-and-conditions" element={<TermsandCondition />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </>
  );
};
