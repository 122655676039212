import React from "react";
import hmicon from "../../img/hm-icon3-1.png";
import hmicon1 from "../../img/hm-icon5-1.png";
import hmicon2 from "../../img/hm-icon6-1.png";
import hmicon3 from "../../img/hm-icon7-1.png";
import { Box } from "@mui/material";

const CompanySpecs = () => {
  return (
    <div className="company-spec">
      <Box className="mainBox">
        <div className="Box">
          <div className="subBox">
            <img src={hmicon} alt="icon" />
            <span>One Version of Truth</span>
            <p>
              Based on a zero-reconciliation system, with all stakeholder roles
              configured on a permissioned blockchain accessing the same source
              data.
            </p>
          </div>
        </div>
        <div className="Box">
          <div className="subBox">
            <img src={hmicon1} alt="icon" />
            <span>Automated & Seamless</span>
            <p>
              Use of Al for supporting due diligence smart contracts for
              structuring tranches, payment waterfalls, and automated reporting
              based on real-time data.
            </p>
          </div>
        </div>
      </Box>
      <Box className="mainBox">
        <div className="Box">
          <div className="subBox">
            <img src={hmicon2} alt="icon" />
            <span>Traceability & Transparency</span>
            <p>
              Immutable audit-trail and a real-time view into the underlying
              asset based on role definitions; lowers compliance costs including
              new ESMA (Europe) regulations.
            </p>
          </div>
        </div>
        <div className="Box">
          <div className="subBox">
            <img src={hmicon3} alt="icon" />
            <span>Speed and Efficiency</span>
            <p>
              Through INTAINMARKETS, users have the option of option of digital
              issuance where the end-to-end transaction is digital and executed
              in real-time. Subsequent administration is automated
              with INTAINADMIN.
            </p>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default CompanySpecs;
