import React from "react";
import Header from "../../../components/Header/Header";
import EventsIMG from "../../../img/Events5.svg";
import Footer from "../../../components/Footer/Footer";
const Events = () => {
  return (
    <div className="custom-managment-page">
      <Header />
      <div className="insight-container">
        <img src={EventsIMG} alt="Image" />
        <div className="insight-content" style={{ paddingBottom: "0px" }}>
          <header>
            <h2>Blockchain Real Estate Summit 2022</h2>
          </header>
        </div>
        <section className="insight-sub-content-1">
          <p>
            Our CEO Siddharth joins Avalanche AVAX President, John Wu for a
            fireside chat at the Blockchain Real Estate Summit to talk about
            future of MBS and how it is being built on blockchain. (MBS is a
            multi-trillion dollar asset class with Federal Reserve being the
            largest MBS investor){" "}
          </p>
          <p>Block your calendar: Feb 26 2:00-2:50</p>
        </section>
      </div>
    </div>
  );
};

const Events5 = () => {
  return (
    <div>
      <Events />
      <Footer />
    </div>
  );
};

export default Events5;
