import React, { useEffect, useRef, useState, forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import sid from "../../img/Ellipse 10.png";
import sanjay from "../../img/Ellipse 15.png";
import guillaume from "../../img/guilleme.svg";
import linkedin from "../../img/LinkedIn (1).png";
import steve from "../../img/steve.svg";
import arun from "../../img/arun.svg";
import hugh from "../../img/Hugh.svg";
import dave from "../../img/dave.svg";
import manmeet from "../../img/manmeet.svg";
import ravi from "../../img/ravi.svg";
import gary from "../../img/gary.svg";
import paul from "../../img/paul.svg";
import fintop from "../../img/fintop.svg";
import blizzard from "../../img/blizzard.svg";
import blockseed from "../../img/blockseed.svg";
import hamon from "../../img/hamon.svg";
import career1 from "../../img/career1.svg";
import career2 from "../../img/career2.svg";
import career3 from "../../img/career3.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Carousel.css";
import { Box, Button, Dialog } from "@mui/material";
import { ChevronRight, Close } from "@mui/icons-material";
import Header from "../../components/Header/Header";
import { Intern } from "../../Actions/Action";

const teamMembers = [
  {
    name: "Siddhartha S",
    title: "Founder & CEO",
    description:
      "Siddhartha has held executive leadership and board positions in Financial Services operations and technology.",
    linkedin: "https://www.linkedin.com/in/siddhu75/",
    image: sid,
  },
  {
    name: "Sanjay Nishank",
    title: "Co-founder & COO",
    description:
      "Sanjay has led enterprise implementation and software product development programs in mortgage/retail banks, investment banks.",
    linkedin: "https://www.linkedin.com/in/sanjaynishank/",
    image: sanjay,
  },
  {
    name: "Guillaume Fillebeen",
    title: "VP, Products",
    description:
      "Structured finance industry veteran with stint at Moody’s; manages deal modeling for Intain.",
    linkedin: "https://www.linkedin.com/in/guillaumefillebeen/",
    image: guillaume,
  },
  {
    name: "Steve Odesser",
    title: "SVP, Growth",
    description:
      "Structured finance industry veteran with experience across origination, structuring and execution.",
    linkedin: "https://www.linkedin.com/in/steveodesser/",
    image: steve,
  },
  {
    name: "Arun Gandhi",
    title: "VP, Operations",
    description:
      "Trustee and paying agent operations across MBS, ABS, CDO and CLO transactions.",
    linkedin: "https://www.linkedin.com/in/arun-kumar-gandhi-73302513/",
    image: arun,
  },
];

const BoardMembers = [
  {
    name: "Hugh Simon",
    image: hugh, // Make sure to import or require this image
    title: "Investor and Advisor, Intain & CEO, Hamon Investment Group",
    description:
      "Hugh established Hamon Investment Group in 1989 and has focused Hamon’s fund management efforts on increasing.",
    linkedin: "https://www.hamon.com.hk/",
  },
  {
    name: "Dave Shuler",
    image: dave,
    title: "Director, Intain & Co-Founder Y2X",
    description:
      "David is the co-founder of Y2X that specializes in regulated token issuances and innovative capital raising mechanisms. David previously served.",
    linkedin: "https://www.linkedin.com/in/david-shuler-60b39613/",
  },
  {
    name: "Manmeet Singh",
    image: manmeet,
    title: "Director, Intain; Founder, Blockseed Ventures",
    description:
      "Manmeet has been an entrepreneur throughout his career. He established M&M Corporation in 2005 to provide China focused Strategy.",
    linkedin: "https://www.linkedin.com/in/manmeetsingh/",
  },
  {
    name: "B Ravishankar",
    image: ravi, // Import or require the image beforehand
    title: "Director Intain, Senior Advisor",
    description:
      "Having worked across the industry over 35 years, from founding a company (Lister Technologies), to heading HR for Deutsche.",
    linkedin: "https://www.linkedin.com/in/ravishanks/",
  },
  {
    name: "Gary Miller",
    image: gary,
    title: "Advisor; Formerly President Intain, Inc.",
    description:
      "Setup Intain in the US as its President; Structured Finance industry veteran with stints at Banc One Capital (JP Morgan) and Credit Agricole.",
    linkedin: "https://www.linkedin.com/in/gary-miller-1397942/",
  },
  {
    name: "Paul Mangione",
    image: paul,
    title: "Strategic Advisor",
    description:
      "Principal at Fort Schuyler Advisors, concentrating on structured credit, consumer and residential whole loans and valuation; background in portfolio management with public investment companies, private funds and broker/dealers.",
    linkedin: "https://www.linkedin.com/in/pmangione/",
  },
];

const ManagmentTeam = () => {
  return (
    <Box className="team-container">
      {teamMembers.map((member, index) => (
        <div key={index} className="team-circle">
          <img
            src={member.image}
            alt={member.name}
            // style={
            //   member.name === "Guillaume Fillebeen"
            //     ? { height: "193px", position: "relative", top: "11px" }
            //     : {}
            // }
            className={member.name === "Guillaume Fillebeen" ? "member-style" : ""}
          />
          <div className="team-info">
            <h3>{member.name}</h3>
            <p>{member.title}</p>
            <label>{member.description}</label>
            <div className="team-social">
              <a
                href={member.linkedin}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={linkedin}
                  alt="LinkedIn"
                  style={
                    member.name === "Guillaume Fillebeen"
                      ? { position: "relative", top: "3px" }
                      : {}
                  }
                />
              </a>
            </div>
          </div>
        </div>
      ))}
    </Box>
  );
};

const BoardTeam = () => {
  return (
    <Box className="managment-container">
      {BoardMembers.map((member, index) => (
        <div key={index} className="managment-circle">
          <img
            src={member.image}
            alt={member.name}
            style={
              member.name === "Hugh Simon" || member.name === "Dave Shuler"
                ? { position: "relative", top: "4%" } // Apply custom styles here
                : {}
            }
          />
          <div
            className="managment-info"
            // style={
            //   member.name === "Arun Gandhi"
            //     ? { height: "235%"}
            //     : {}

            // }
          >
            <h3>{member.name}</h3>
            <p>{member.title}</p>
            <label
              style={
                member.name === "Paul Mangione"
                  ? { width: "100%" } // Apply custom styles here
                  : {}
              }
            >
              {member.description}
            </label>
            <div className="managment-social">
              <a
                href={member.linkedin}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={linkedin} alt="LinkedIn" />
              </a>
            </div>
          </div>
        </div>
      ))}
    </Box>
  );
};
const Investor = () => {
  const settings = {
    dots: true, // Show dots navigation below the carousel
    infinite: true, // Infinite looping
    speed: 1000, // Transition speed
    slidesToShow: 4, // Number of slides to show at once
    slidesToScroll: 1, // Number of slides to scroll at once
    autoplay: true, // Enable autoplay
    pauseOnHover: true, // Pause autoplay when hovering over the carousel
    arrows: false, // Disable navigation arrows
    responsive: [
      {
        breakpoint: 1024, // For screens 1024px and below
        settings: {
          slidesToShow: 3, // Show 3 slides at a time
        },
      },
      {
        breakpoint: 768, // For screens 768px and below (tablets)
        settings: {
          slidesToShow: 2, // Show 2 slides at a time
        },
      },
      {
        breakpoint: 480, // For screens 480px and below (mobile)
        settings: {
          slidesToShow: 1, // Show 1 slide at a time
        },
      },
    ],
  };

  return (
    <Box className="investor">
      <div className="investor-title platform">
        <h1>Investors</h1>
        <span></span>
      </div>

      <div className="carousel-container inv-carousel">
        <Slider {...settings}>
          <div>
            <div className="carousel-item">
              <img src={fintop} alt="Image" />
            </div>
          </div>
          <div>
            <div className="carousel-item">
              <img src={blizzard} alt="Image" />
            </div>
          </div>
          <div>
            <div className="carousel-item">
              <img src={blockseed} alt="Image" />
            </div>
          </div>
          <div>
            <div className="carousel-item">
              <img src={hamon} alt="Image" />
            </div>
          </div>
        </Slider>
      </div>

      <style>
        {`
          .slick-dots li {
            margin: 0 3px; /* Adjust gap between dots */
          }
          .slick-dots li button:before {
            content: '';
            font-size: 0; /* Remove default dash styling */
            width: 10px;
            height: 10px;
            background-color: #212121; /* Default inactive dot color */
            border-radius: 50%; /* Make dots circular */
            display: inline-block;
          }
          .slick-dots li.slick-active button:before {
            background-color: #ffffff !important; /* Active dot color */
          }
          .slick-dots li button:hover:before {
            background-color: #212121 !important; /* Ensure hover keeps the inactive dot color */
          }
          .slick-dots {
            bottom: -70px !important; /* Adjust position */
            right: 3%;
          }

          /* Custom styling for slick dots (circle dots) */
          .slick-dots li {
            margin: 0 3px; /* Space between dots */
          }

          .slick-dots li button:before {
            content: '';
            font-size: 0; /* Remove default dash styling */
            width: 10px; /* Width of the dot */
            height: 10px; /* Height of the dot */
            background-color: #000; /* Color of the dots */
            border-radius: 50%; /* Make the dots circular */
            display: inline-block; /* Ensure the dots are displayed correctly */
          }

          .slick-dots li.slick-active button:before {
            background-color: #000 !important; /* Active dot color */
          }

          .slick-dots {
            bottom: -0px; /* Adjust position if needed */
            display: flex;
            justify-content: center; /* Center align the dots */
          }

          /* Media queries for responsiveness */
          @media (max-width: 1024px) {
            .carousel-container {
              padding: 40px; /* Adjust padding for medium screens */
            }
          }

          @media (max-width: 768px) {
            .carousel-container {
              padding: 20px; /* Adjust padding for tablets */
            }
          }

          @media (max-width: 480px) {
            .carousel-container {
              padding: 10px; /* Adjust padding for small screens */
            }
          }
        `}
      </style>
    </Box>
  );
};

const Careers = forwardRef((props, ref) => {
  return (
    <Box className="career" ref={ref}>
      <div
        className="platform-wh"
        style={{ position: "relative", right: "1em" }}
      >
        <h1>Careers</h1>
        <span></span>
      </div>
      <div className="career1">
        <img src={career1} alt="Image" />
        <div className="career1-content">
          <label>
            We understand the impact – benefits and risk – of technology in
            financial services. Our employees work in an environment that blends
            a research lab and a start-up
          </label>
        </div>
      </div>
      <div className="career2">
        <div className="career2-content">
          <label>
            We are committed to diversity in Fintech and more than 50% of our
            current employees are
            <br />
          </label>
          <span>WOMEN</span>
        </div>
        <img src={career2} alt="Image" />
        <img src={career3} alt="Image" />
      </div>
    </Box>
  );
});
const JobOpenings = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    cv: null,
    linkedInProfile: "",
    coverLetter: "",
  });
  const [wordCount, setWordCount] = useState(0);
  const maxWords = 250;
  const [fileName, setFileName] = useState("");
  const [loader, setLoader] = useState(false);

  const handleFileUpload = (event) => {
    console.log(event, "eee");
    const file = event.target.files[0];
    setFileName(file ? file.name : "");
    console.log(file ? file.name : "", "fileee");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value, // Make sure email field is updated here
    }));
    if (name === "coverLetter") {
      setWordCount(value.split(/\s+/).filter(Boolean).length);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    const form = new FormData();

    // Append form data
    form.append("Fname", formData.firstName);
    form.append("Lname", formData.lastName);
    form.append("mail", formData.email);
    form.append("mobileno", formData.mobileNumber);
    form.append("linkedIn", formData.linkedInProfile);
    form.append("message", formData.coverLetter);

    // Append the file (resume)
    const resumeInput = document.getElementById("resume-upload");
    if (resumeInput.files.length > 0) {
      form.append("cv", resumeInput.files[0]);
    }

    await Intern(form);
    setLoader(false);
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      mobileNumber: "",
      cv: "",
      linkedInProfile: "",
      coverLetter: "",
    });
    setFileName("");
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true); // Open modal
  };

  const closeModal = () => {
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      mobileNumber: "",
      cv: "",
      linkedInProfile: "",
      coverLetter: "",
    });
    setFileName("");
    setIsOpen(false); // Close modal
  };
  return (
    <Box className="jobopening-container">
      <div
        className="platform-wh"
        // style={{ position: "relative", right: "1em" }}
      >
        <h1>Job Openings</h1>
        <span></span>
      </div>
      <Box className="jobopening-box">
        <div className="jobopening">
          <label>
            We are always looking for technology and financial services
            professionals.
          </label>
          <div className="job-btn">
            <Button
              variant="contained"
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/company/intain/",
                  "_blank"
                )
              }
            >
              Openings
            </Button>
          </div>
        </div>

        {/* <div className="jobopening"> */}
        <div className="jobopening">
          <label>Want to intern with Intain?</label>
          <div
            className="job-btn"
            style={{ position: "relative", top: "15%" }}
          >
            <Button variant="contained" onClick={openModal}>
              Apply
            </Button>
          </div>
        </div>
        {/* </div> */}
      </Box>

      <>
        <Dialog
          open={isOpen}
          onClose={closeModal}
          aria-labelledby="customized-dialog-title"
        >
          <div className="form-container custom-managment-form">
            <div className="closeicon">
              <Close onClick={closeModal} className="form-icon"></Close>
            </div>
            <form onSubmit={handleSubmit}>
              {/* Row for First Name and Last Name */}
              <div className="row">
                <div className="form-group">
                  <label>First Name:</label>
                  <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label>Last Name:</label>
                  <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              {/* Row for Email and Mobile Number */}
              <div className="row">
                <div className="form-group">
                  <label>Email:</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label>Mobile Number:</label>
                  <input
                    type="tel"
                    name="mobileNumber"
                    value={formData.mobileNumber}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              {/* Separate row for Resume Upload */}
              <div className="form-group-upload">
                <label htmlFor="resume-upload" className="upload-label">
                  Resume Upload:
                </label>
                <input
                  type="file"
                  id="resume-upload"
                  name="cv"
                  accept=".pdf,.doc,.docx"
                  onChange={handleFileUpload}
                  required
                  style={{ display: "none" }}
                />
                <div className="file-upload-container">
                  <span
                    className="file-upload-btn"
                    onClick={() => {
                      console.log("File upload button clicked");
                      document.getElementById("resume-upload").click(); // Open file dialog
                    }}
                  >
                    Choose File
                  </span>
                  <span className="file-name">{fileName}</span>
                </div>
              </div>

              {/* Separate row for LinkedIn Profile with full width */}
              <div className="form-group full-width">
                <label>LinkedIn Profile:</label>
                <input
                  type="url"
                  name="linkedInProfile"
                  value={formData.linkedInProfile}
                  onChange={handleChange}
                  required
                />
              </div>

              {/* Cover Letter */}
              <div className="form-group full-width">
                <label>Why intern with us?:</label>
                <textarea
                  name="coverLetter"
                  placeholder="You will be a good fit for us if you have a degree in Computer Science or
in Finance or academic project work in Blockchain (any protocol but
preferably Hyperledger Fabric and R3 Corda ), JavaScript (React, Node.js)
or Cloud Architecture etc."
                  value={formData.coverLetter}
                  onChange={handleChange}
                  maxLength={maxWords * 5} // Assuming avg word length = 5 chars
                  required
                />
                <p>
                  {wordCount} / {maxWords} words
                </p>
              </div>

              <button type="submit">{loader ? "Loading..." : "Submit"}</button>
            </form>
          </div>
        </Dialog>
      </>
    </Box>
  );
};

const NewsandEvents = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const [isDarkMode, setIsDarkMode] = useState(
    sessionStorage.getItem("dark-theme") === "dark"
  );
  useEffect(() => {
    const checkTheme = () => {
      const darkTheme = sessionStorage.getItem("dark-theme") === "dark";
      setIsDarkMode(darkTheme);
    };

    // Directly listen for manual theme change events (if user toggles dark mode within same tab)
    window.addEventListener("storage", checkTheme);

    // Optional: If you have a theme toggle button elsewhere, you can listen to it
    const interval = setInterval(checkTheme, 10); // Checking every half second if the theme changes

    return () => {
      window.removeEventListener("storage", checkTheme);
      clearInterval(interval); // Clean up the interval on component unmount
    };
  }, []);

  const handleRedirect = () => {
    navigate("/events");
  };
  const handleNews = () => {
    navigate("/news");
  };
  const handleEvents = () => {
    navigate("/events");
  };

  return (
    <Box className="newsandevents" ref={ref}>
      <div className="platform-wh newsandevents-platform">
        <h1>News & Events</h1>
        <span></span>
      </div>

      <Box className="newsandevents-container">
        <div className="newsandevents-container-nw">
          <label>
            END Capital and Intain Partner for Digital Structured Finance
            Transactions
          </label>
          <p>Press Release</p>
        </div>
        <div className="newsandevents-container-nw">
          <label>
            Structured Finance Platform Intain Opens Tokenized Marketplace for
            Asset-Backed Securities​{" "}
          </label>
          <a href="https://www.coindesk.com/">
            <p style={{ paddingTop: "13px" }}>www.coindesk.com</p>
          </a>
        </div>
      </Box>

      <div className="newsbtn">
        <Button variant="contained" onClick={handleNews}>
          More News
        </Button>
      </div>

      <div className="newsevents-container">
        <div
          className={`newsevents-container-nw ${
            isDarkMode ? "news-img1" : "news-img1-light"
          }`}
        >
          <div className="event">Event</div>
          <span>March 13, 2023</span>
          <label>
            END Capital and Intain Partner for Digital Structured Finance
            Transactions
          </label>
          <p>
            Coming up on the next Powering Business with Blockchain, Intain
            joins to talk about IntainMARKETS, a new tokenized marketplace for
            asset-backed securities, being built on Avalanche. The...
          </p>
          <button className="news-btn" onClick={handleRedirect}>
            Read More <ChevronRight className="news-btn-icon" />
          </button>
        </div>

        <div
          className={`newsevents-container-nw ${
            isDarkMode ? "news-img2" : "news-img2-light"
          }`}
        >
          <div className="event">Event</div>
          <span>February 14, 2023</span>
          <label>
            Structured Finance Platform Intain Opens Tokenized Marketplace for
            Asset-Backed Securities
          </label>
          <a href="https://www.coindesk.com/">
            <p style={{ paddingTop: "13px" }}>www.coindesk.com</p>
          </a>
          <button className="events-btn" onClick={handleRedirect}>
            Read More <ChevronRight className="news-btn-icon" />
          </button>
        </div>
      </div>

      <div className="newsbtn">
        <Button variant="contained" onClick={handleEvents}>
          More Events
        </Button>
      </div>
    </Box>
  );
});
const Team = () => {
  const [isInsightsInView, setIsInsightsInView] = useState(false);
  const [isProductInView, setIsProductInView] = useState(false);
  const [careerView, setCareerView] = useState(false);
  const location = useLocation();
  const aboutus = useRef(null); // Ref for the Insights section
  const newsansevents = useRef(null); // Ref for the Product Release section
  const career = useRef(null); // Ref for the Product Release section

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const scrollToNewsEvents = queryParams.get("section") === "news-events";
    const scrollToCareer = queryParams.get("section") === "careers";

    if (scrollToNewsEvents && newsansevents.current) {
      newsansevents.current.scrollIntoView({ behavior: "smooth" });
    }

    if (scrollToCareer && career.current) {
      career.current.scrollIntoView({ behavior: "smooth" });
    }

    const insightObserver = new IntersectionObserver(
      ([entry]) => setIsInsightsInView(entry.isIntersecting),
      { threshold: 0.1 }
    );

    const productObserver = new IntersectionObserver(
      ([entry]) => setIsProductInView(entry.isIntersecting),
      { threshold: 0.1 }
    );

    const careerObserver = new IntersectionObserver(
      ([entry]) => setCareerView(entry.isIntersecting),
      { threshold: 0.1 }
    );

    if (aboutus.current) insightObserver.observe(aboutus.current);
    if (newsansevents.current) productObserver.observe(newsansevents.current);
    if (career.current) careerObserver.observe(career.current);

    return () => {
      if (aboutus.current) insightObserver.unobserve(aboutus.current);
      if (newsansevents.current)
        productObserver.unobserve(newsansevents.current);
      if (career.current) careerObserver.unobserve(career.current);
    };
  }, [location]);

  const scrollTo = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const div = document.querySelector(".centered-div");
      if (window.scrollY > 50) {
        div.classList.add("scrolled");
      } else {
        div.classList.remove("scrolled");
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <div className="custom-managment-page">
      <Header />
      <div className="centered-div">
        <button
          className={isInsightsInView ? "underlined" : ""}
          onClick={() => scrollTo(aboutus)}
        >
          About Us
        </button>
        <button
          className={isProductInView ? "underlined" : ""}
          onClick={() => scrollTo(newsansevents)}
        >
          News & Events
        </button>
        <button
          className={careerView ? "underlined" : ""}
          onClick={() => scrollTo(career)}
        >
          Careers
        </button>
      </div>
      <div className="team-title platform" ref={aboutus}>
        <h1>Management Team</h1>
        <span></span>
      </div>
      <ManagmentTeam />
      <div className="team-title platform board">
        <h1>Board and advisors</h1>
        <span></span>
      </div>
      <BoardTeam />

      <Investor />
      <NewsandEvents ref={newsansevents} />
      <Careers ref={career} />
      <JobOpenings />
    </div>
  );
};

export default Team;
