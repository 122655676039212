import React from "react";
import Header from "../../../components/Header/Header";
import Insightimg from "../../../img/InsightFour.svg";
import Footer from "../../../components/Footer/Footer";
const Insight = () => {
  return (
    <div className="custom-managment-page">
      <Header />
      <div className="insight-container" style={{ paddingTop: "100px" }}>
        <img src={Insightimg} alt="Image" />
        <div className="insight-content">
          <header>
            <h2>
              Distributed Ledger Technology And The Future of Securitization{" "}
            </h2>
            <div className="insight-content-author">
              <span>IBTimes</span>
              <p>November 2, 2019</p>
            </div>
          </header>

          <section className="insight-sub-content">
            <p>
              Like many people a few years ago, I had been hearing about
              cryptocurrency for some time, but largely dismissed it probably
              because it was something I really couldn’t get my head around. But
              in 2016 I heard the word “blockchain” mentioned for the first
              time, and that piqued my interest enough to Google it to find out
              what it was. I learned that blockchain and distributed ledger
              technology can take many forms and have many uses, and that it
              underpins exciting changes in numerous business practices
              throughout the world – and most importantly that there was an
              expansive world of blockchain beyond mere cryptocurrency. But as
              someone who largely views the business world through a structured
              finance filter, my “genesis thought” after reading perhaps only
              one or two paragraphs about this so-called blockchain technology
              was, wow, this could run securitization transactions. That same
              day, I dove into LinkedIn to find others talking about blockchain
              and so I began networking, and within a few months I was speaking
              on a blockchain panel at the Structured Finance Industry Group’s
              annual conference in Las Vegas.
              <br />
              <a href="https://www.ibtimes.com/distributed-ledger-technology-future-securitization-2762052" target="_blank" rel="noopener noreferrer">
                Read full article
              </a>
            </p>
            <p>
              Article on<a href="https://www.ibtimes.com/" target="_blank" rel="noopener noreferrer"> IBTIMES</a> dated
              02/11/2019
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};
const InsightFour = () => {
  return (
    <div>
      <Insight />
      <Footer />
    </div>
  );
};

export default InsightFour;
