import React, { useState } from "react";
import Conceptualhand from "../../../src/img/conceptual-hand-removebg-preview-1.png";
import { Box, Button, Dialog } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import { Close } from "@mui/icons-material";
import { ContactApi } from "../../Actions/Action";
import "./Hero.css";

const RECAPTCHA_SITE_KEY = "6LdWIYYqAAAAAFKEtiNjf5gz0kSKfZ_jIGNAlybI";

const Hero = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    company: "",
    role: "",
    interest: "",
    companyMail: "",
    message: "",
    isRobot: false,
  });

  const closeDeal = () => {
    setDialogOpen(false);
    setFormData([]);
  };

  const roleOptions = [
    { value: "Accounting Firm", label: "Accounting Firm" },
    { value: "Aggregator", label: "Aggregator" },
    { value: "Arrangers / Advisory", label: "Arrangers / Advisory" },
    { value: "Asset Manager", label: "Asset Manager" },
    { value: "Bank Lender", label: "Bank Lender" },
    { value: "Broker Dealer", label: "Broker Dealer" },
    { value: "CDO / CLO Manager", label: "CDO / CLO Manager" },
    { value: "Individual", label: "Individual" },
    { value: "Institutional Investor", label: "Institutional Investor" },
    { value: "Issuer", label: "Issuer" },
    { value: "Law Firm", label: "Law Firm" },
    { value: "Mortgage Insurer", label: "Mortgage Insurer" },
    { value: "Originator", label: "Originator" },
    {
      value: "Other Financial Institution",
      label: "Other Financial Institution",
    },
    { value: "Rating Agency", label: "Rating Agency" },
    { value: "Servicer", label: "Servicer" },
    { value: "Trustee", label: "Trustee" },
    { value: "Underwriter", label: "Underwriter" },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoader(true);
    await ContactApi(formData);

    setFormData({
      name: "",
      company: "",
      role: "",
      interest: "",
      companyMail: "",
      message: "",
      isRobot: false,
    });
    setDialogOpen(false);
    setLoader(false);
  };

  const onRecaptchaChange = (value) => {
    console.log("Recaptcha value:", value);
    // Set the formData 'isRobot' field to true or false based on the recaptcha result
    setFormData({ ...formData, isRobot: value ? true : false });
  };

  return (
    <div>
      <Box className="hero-container">
        <div className="left-panel">
          <h1 className="title">
            The
            <span className="custom-title"> Future of</span> Structured Finance
            is <span className="custom-title">Digital</span>
          </h1>
          <p className="description">
            Digital issuance integrated with automated administration is here,
            with all stakeholders connected on a blockchain network.
          </p>
          <Button onClick={() => setDialogOpen(true)} variant="contained">
            Request a Demo
          </Button>
          <div className="underline"></div>
        </div>

        <div className="right-panel">
          <img src={Conceptualhand} alt="Conceptal Hand" />
        </div>
      </Box>
      <>
        <Dialog
          onClose={closeDeal}
          aria-labelledby="customized-dialog-title"
          open={dialogOpen}
        >
          <div className="demo-container custom-managment-form">
            <div className="closeicon">
              <Close onClick={closeDeal} className="icon"></Close>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="contact-us-container-form-input">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="contact-us-container-form-input">
                <label htmlFor="company">Company</label>
                <input
                  type="text"
                  id="company"
                  name="company"
                  value={formData.company}
                  onChange={handleInputChange}
                />
              </div>
              <div className="contact-us-container-form-input">
                <label htmlFor="role">Role</label>
                <select
                  id="role"
                  name="role"
                  value={formData.role}
                  onChange={handleInputChange}
                >
                  <option value="">Select Role</option>
                  {roleOptions.map((role) => (
                    <option key={role.value} value={role.value}>
                      {role.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="contact-us-container-form-input">
                <label htmlFor="interest">Interest</label>
                <select
                  id="interest"
                  name="interest"
                  value={formData.interest}
                  onChange={handleInputChange}
                >
                  <option value="">Select Interest</option>
                  <option value="intainadmin">
                    IntainADMIN(Integrated Data Operations)
                  </option>
                  <option value="intainmarket">
                    IntainMARKETS(Digital Issuance and Admin)
                  </option>
                  <option value="intainai">
                    IntainAI(Automated Document Processing)
                  </option>
                  <option value="Genear">General Enquiry</option>
                </select>
              </div>
              <div className="contact-us-container-form-input">
                <label htmlFor="companyMail">Company Mail ID</label>
                <input
                  type="email"
                  id="companyMail"
                  name="companyMail"
                  value={formData.companyMail}
                  onChange={handleInputChange}
                />
              </div>
              <div className="contact-us-container-form-input">
                <label htmlFor="message">Message</label>
                <textarea
                  style={{ height: "100px" }}
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  maxLength="500"
                />
              </div>
              <div className="recaptcha-wrapper">
                <ReCAPTCHA
                  sitekey={RECAPTCHA_SITE_KEY}
                  onChange={onRecaptchaChange}
                />
              </div>
              <button type="submit">{loader ? "Loading..." : "Submit"}</button>
              <label style={{ paddingTop: "20px" }}>
                By clicking submit application, You understand and agree to the
                terms of use and <a href="/privacy-policy">privacy policy.</a>
              </label>
            </form>
          </div>
        </Dialog>
      </>
    </div>
  );
};

export default Hero;

