import React from "react";
import Header from "../../../components/Header/Header";
import EventsIMG from "../../../img/Events10.svg";
import Footer from "../../../components/Footer/Footer";
const Events = () => {
  return (
    <div className="custom-managment-page">
      <Header />
      <div className="insight-container">
        <img src={EventsIMG} alt="Image" />
        <div className="insight-content" style={{ paddingBottom: "0px" }}>
          <header>
            <h2>Join Intain at RISE Conference, Hong Kong</h2>
          </header>
        </div>
        <section className="insight-sub-content-1">
          <p>
            Intain will be at RISE Conference, Hong Kong from 8th-11th July, do
            come by to really understand the disruption we are trying to bring
            in your Industry using Blockchain, Artificial Intelligence and
            Machine learning. Ashish Singh and Rahul Chandra will be there to
            greet you.{" "}
          </p>
        </section>
      </div>
    </div>
  );
};

const Events10 = () => {
  return (
    <div>
      <Events />
      <Footer />
    </div>
  );
};

export default Events10;
