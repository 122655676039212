import React from "react";
import Header from "../../components/Header/Header";
import Hero from "./Hero";
import Platform from "./Platform";
import CompanySpecs from "./CompanySpecs";
import FeaturedPartners from "./FeaturedPartners";
import ClientTestimonials from "./ClientTestimonials";
import WhatsNew from "./WhatsNew";
import LinkedIn from "./LinkedIn";
import Footer from "../../components/Footer/Footer";

const HomepageStatic = () => {
  return (
    <div>
      <Header />
      <Hero />
      <Platform />
      <CompanySpecs />
      <FeaturedPartners />
      <ClientTestimonials />
      <WhatsNew />
      <LinkedIn />
      <Footer />
    </div>
  );
};

export default HomepageStatic;
