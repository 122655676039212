import React from "react";
import Header from "../../../components/Header/Header";
import EventsIMG from "../../../img/Events7.svg";
import Footer from "../../../components/Footer/Footer";
const Events = () => {
  return (
    <div className="custom-managment-page">
      <Header />
      <div className="insight-container">
        <img src={EventsIMG} alt="Image" />
        <div className="insight-content" style={{ paddingBottom: "0px" }}>
          <header>
            <h2>LendIt FinTech</h2>
          </header>
        </div>
        <section className="insight-sub-content-1">
          <p>
            Intain is excited to attend the conference and network with leading
            industry professionals and explore avenues and opportunities for
            growth, in both TradFi and DeFi ventures aimed at improving
            structured finance through blockchain efficiencies.{" "}
          </p>
        </section>
      </div>
    </div>
  );
};

const Events7 = () => {
  return (
    <div>
      <Events />
      <Footer />
    </div>
  );
};

export default Events7;
