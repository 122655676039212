import React from "react";
import Header from "../../../components/Header/Header";
import EventsIMG from "../../../img/Events.svg";
import Footer from "../../../components/Footer/Footer";
const Events = () => {
  return (
    <div className="custom-managment-page">
      <Header />
      <div className="insight-container">
        <img
          src={EventsIMG}
          alt="Image"
        />
        <div className="insight-content" style={{ paddingBottom: "0px" }}>
          <header>
            <h2>Powering Business with Blockchain: Avalanche & Intain</h2>
          
          </header>
        </div>
        <section className="insight-sub-content-1">
          <p>
            Coming up on the next Powering Business with
            Blockchain, Intain joins to talk about IntainMARKETS, a new
            tokenized marketplace for asset-backed securities, being built on
            Avalanche.{" "}
          </p>
          <p>
            The live webinar will focus on IntainMARKETS, and how leveraging the
            power and scalability of Avalanche will help Intain facilitate
            efficient, cost-effective and transparent asset issuance in the
            trillion dollar structured finance market.{" "}
          </p>
          <p>When: Tuesday, March 14th @ 11am ET</p>
          <p>Who :</p>
          <p>
            Siddartha : Founder & CEO, Intain
            <br />
            Eric Mitzel : Vice President, Intain
            <br />
            Morgan Krupetsky, Director of BD for Institutions & Capital Markets,
            Ava Labs
          </p>
          <p>
            RSVP for your spot today: 
             <a href="https://lnkd.in/gbGAqkbN">https://lnkd.in/gbGAqkbN</a> 
          </p>
        </section>
      </div>
    </div>
  );
};

const Events1 = () => {
  return (
    <div>
      <Events />
      <Footer />
    </div>
  );
};

export default Events1;
