import React from "react";
import Header from "../../../components/Header/Header";
import EventsIMG from "../../../img/Events4.svg";
import Footer from "../../../components/Footer/Footer";
const Events = () => {
  return (
    <div className="custom-managment-page">
      <Header />
      <div className="insight-container">
        <img src={EventsIMG} alt="Image" />
        <div className="insight-content" style={{ paddingBottom: "0px" }}>
          <header>
            <h2>SFA Vegas 2023 – 101: Blockchain</h2>
          </header>
        </div>
        <section className="insight-sub-content-1">
          <p>
            Blockchain has an important role in the digitalization of markets,
            but it is only a part of the whole tech stack. At #SFVegas2023
            Intain’s VP Growth Eric Mitzel how blockchain is reaching mainstream
            adoption in Structured Finance.{" "}
          </p>
          <p>Block your calendar: Feb 26 2:00-2:50</p>
        </section>
      </div>
    </div>
  );
};

const Events4 = () => {
  return (
    <div>
      <Events />
      <Footer />
    </div>
  );
};

export default Events4;
