import React from "react";
import Header from "../../../components/Header/Header";
import EventsIMG from "../../../img/Events8.svg";
import Footer from "../../../components/Footer/Footer";
const Events = () => {
  return (
    <div className="custom-managment-page">
      <Header />
      <div className="insight-container">
        <img src={EventsIMG} alt="Image" />
        <div className="insight-content" style={{ paddingBottom: "0px" }}>
          <header>
            <h2>Draper Security Token Summit</h2>
          </header>
        </div>
        <section className="insight-sub-content-1">
          <p>
            If you have an interest in brining real world assets into DeFi,
            either as an issuer, investor, intermediary or venture investor, or
            just want to know about how we made onboarding of traditional
            structured finance deals on blockchain, so boringly routine, we
            would love to talk to you at the Security Token Summit.{" "}
          </p>
        </section>
      </div>
    </div>
  );
};

const Events8 = () => {
  return (
    <div>
      <Events />
      <Footer />
    </div>
  );
};

export default Events8;
