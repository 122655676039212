import React from "react";
import Header from "../../../components/Header/Header";
import EventsIMG from "../../../img/Events2.svg";
import Footer from "../../../components/Footer/Footer";
const Events = () => {
  return (
    <div className="custom-managment-page">
      <Header />
      <div className="insight-container">
        <img src={EventsIMG} alt="Image" />
        <div className="insight-content" style={{ paddingBottom: "0px" }}>
          <header>
            <h2>SFA Vegas 2023</h2>
          </header>
        </div>
        <section className="insight-sub-content-1">
          <p>
            Join the Intain team for a scheduled demo at our booth or set-up a
            convenient time through the conference app.
          </p>
        </section>
      </div>
    </div>
  );
};

const Events2 = () => {
  return (
    <div>
      <Events />
      <Footer />
    </div>
  );
};

export default Events2;
