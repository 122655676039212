import React, { useState, useEffect } from "react";
import umb from "../../img/umb.png";
import wsfs from "../../img/wsfsinstitutionalservices-1.png";
import umblight from "../../img/umb-light.svg";
import { Box } from "@mui/material";
const FeaturedPartners = () => {
  const [isDarkMode, setIsDarkMode] = useState(
    sessionStorage.getItem("dark-theme") === "dark"
  );

  useEffect(() => {
    const checkTheme = () => {
      const darkTheme = sessionStorage.getItem("dark-theme") === "dark";
      setIsDarkMode(darkTheme);
    };

    window.addEventListener("storage", checkTheme);
    const interval = setInterval(checkTheme, 10); // Checking every half second if the theme changes

    return () => {
      window.removeEventListener("storage", checkTheme);
      clearInterval(interval); // Clean up the interval on component unmount
    };
  }, []);

  return (
    <Box className="green-page  partner">
      <div className="platform">
        <div>Featured Partners and Clients</div>
        <span></span>
      </div>
      <div class="d-flex gap-custom mt-custom align-items-center">
      <div className="umbimg">
          <img alt="umb" src={isDarkMode ? umb : umblight} />
        </div>
        <div className="wsfs">
          <img alt="wsfs" src={wsfs} />
        </div>
      </div>
    </Box>
  );
};

export default FeaturedPartners;
