import React from "react";
import Header from "../../../components/Header/Header";
import Insightimg from "../../../img/InsightThree.svg";
import Footer from "../../../components/Footer/Footer";
const Insight = () => {
  return (
    <div className="custom-managment-page">
      <Header />
      <div className="insight-container" style={{ paddingTop: "100px" }}>
        <img src={Insightimg} alt="Image" />
        <div className="insight-content">
          <header>
            <h2>Prime Time for Invoice Factoring </h2>
            <div className="insight-content-author">
              <span>Antonia Simon</span>
              <p>July 9, 2020</p>
            </div>
          </header>

          <section className="insight-sub-content">
            <p>
              Invoice factoring has been on the steady rise for the past two
              decades, and it looks like it’s here to stay. In April, TechNavio
              published its “Global Factoring Market 2020-2024” report,
              predicting that the factoring market is expected to grow by
              $1,308.4 bn USD during 2020-2024. But, with post-pandemic
              hindsight, was their prediction too conservative?
            </p>
            <p>
              The impact of the pandemic has left companies with urgent cash
              needs, desperate for working capital solutions. With collapsing
              demands and supply chain shutdowns, companies are facing poor
              trading conditions, creating a storm of sudden pressure on
              liquidity. Between cash strapped customers and cash-starved MSMEs,
              many businesses have bled into payment obligation delays of
              over 40-60 days.
            </p>
            <p>
              To quell the damage and backstop the United States economy, in
              March, the Federal Reserve introduced support programs and cut
              interest rates to near-zero, returning it to a range of 0 to 0.25
              percent. This high-risk, low-interest environment has created the
              perfect opportunity for invoice factoring. 
            </p>
            <p>
              Invoice finance firms have been quick to the scene, rolling out
              COVID-centric invoice factoring solutions. 
              <a href="https://optimumfinance.co.uk/" target="_blank" rel="noopener noreferrer">Optimum Finance</a>, a UK
              based fintech invoice discounting and factoring solutions firm,
              has spearheaded this movement. Optimum Finance is not only
              launching a 
              <a href="https://www.insidermedia.com/news/south-west/invoice-finance-firm-launches-covid-19-package" target="_blank" rel="noopener noreferrer">
                support package
              </a>{" "}
               for UK SMEs affected by the coronavirus, but also applies &nbsp;
              <a href="https://londonlovesbusiness.com/optimum-finance-uses-ai-to-identify-smes-needing-invoice-finance-2/" target="_blank" rel="noopener noreferrer">
                AI to identify SMEs in need of invoice finance. {" "}
              </a>
               
            </p>
            <p>
              But AI prediction is not the only technological advancement that
              the industry has seen. As a market that was dreamt up to avoid
              monotonous, manual inquiries and accelerate cash flow, invoice
              factoring is the ideal use case for blockchain technology. On a
              decentralized, distributed ledger, an automated factoring process
              facilitates immediate transitions, enhances trust, and promotes
              transparency across the whole discounting process. Governed by a
              smart contract, blockchain-backed invoice factoring provides
              faster, more resilient, low-risk services while preventing
              double-spending.{" "}
            </p>
            <p>
              Today’s current economic environment showcases invoice factoring
              as a lucrative investment. But Intain believes that the potential
              for innovation doesn’t stop here. Rather, the future of invoice
              factoring lies in tokenisation through an asset value blockchain.
              By unitising invoice assets, tockenisation has the ability to
              heighten liquidity and enable even more decentralisation, making
              the investment opportunity more secure.{" "}
            </p>
            <p>
              However, the invoice factoring firms that have adopted
              tockenisation are few and far between. Moreover, they do not have
              the necessary information rails for investors to see the value of
              underlying invoice assets. With no NAV, unlike mutual funds or
              traded stocks, monitoring token asset growth remains opaque. That
              is why Intain’s Asset Value Blockchain (AVB) has sought to solve
              the Achille’s heel of tokenisation by providing real-time
              asset-value insights to token holders. {" "}
            </p>
            <p>
              Given the increasing demand for invoice factoring today, it is
              clear that this market will continue to grow and innovate.
              However, the time-old question remains: are we ready to harness
              these solutions?{" "}
            </p>
           
          </section>
        </div>
      </div>
    </div>
  );
};
const InsighThree = () => {
  return (
    <div>
      <Insight />
      <Footer />
    </div>
  );
};

export default InsighThree;
