import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import award from "../../img/rectangle-49.png";
import avalanche from "../../img/rectangle-53.png";
import SFAVegas from "../../img/rectangle-56.png";
import blockchain from "../../img/image.png";
import SFAVegas2023 from "../../img/SFA-Vegas-Web.png";
import SFABlockChain from "../../img/101-Blockchain-SFA-Vegas.png";
import ABS from "../../img/Vegas.png";
import whatsnew from "../../img/whatsnew.svg";
import { ArrowForwardIos, ArrowBackIos } from "@mui/icons-material";
import { Box } from "@mui/material";
// import Ida from "../../img/Ida.svg";
import Ida from "../../img/IDA_img.png"

const SamplePrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff", // Arrow color
        fontSize: "30px", // Arrow size
        cursor: "pointer",
        position: "absolute",
        left: "-40px",
        zIndex: 1,
        marginTop: "15.5rem",
      }}
      onClick={onClick}
    >
      <span style={{ fontSize: "20px" }}>
        <ArrowBackIos />
      </span>{" "}
      {/* Change the arrow icon as needed */}
    </div>
  );
};

// Custom next arrow component
const SampleNextArrow = (props) => {
  const { onClick } = props;

  return (
    <div className="arrow" onClick={onClick}>
      <span style={{ fontSize: "20px" }}>
        <ArrowForwardIos />
      </span>{" "}
      {/* Change the arrow icon as needed */}
    </div>
  );
};

const WhatsNew = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Show one slide at a time
    // slidesToScroll: 1,
    autoplay: true,
    arrows: true, // Show navigation arrows
    prevArrow: <SamplePrevArrow />, // Custom prev arrow
    nextArrow: <SampleNextArrow />,
    responsive: [
      {
        breakpoint: 1024, // For screens <= 1024px
        settings: {
          slidesToShow: 2, // Show 2 slides at a time
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768, // For screens <= 768px
        settings: {
          slidesToShow: 2, // Show 1 slide at a time for small screens
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 480, // For screens <= 480px
        settings: {
          slidesToShow: 1, // Show 1 slide at a time for extra small screens
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false, // Keep arrows visible on larger screens
        },
      },
    ],
  };

  const slideData = [
    {
      img: Ida,
      title: "IDA: The Virtual Analyst for Structured Finance",
      // description:
      //   "IDA: The Virtual Analyst for Structured Finance on Medium to know more about the analyst that allows you to converse with structured finance data, by Ridhanya and Arvind",
      date: "22 September, 2024",
      link: "https://medium.com/intain/ida-the-virtual-assistant-for-structured-finance-e2bf59230340",
    },
    {
      img: award,
      title:
        "Our Co-founder and COO, Sanjay Nishank, received the award on behalf of Intain.",
      description:
        "Thanks to #G7CR and #EntrepreneurIndia, along with #Microsoft for recognizing the innovative work being done by India at the STAB ISV SUCCESS AWARD 2023.",
      date: "12 March, 2023",
    },
    {
      img: avalanche,
      title: "Powering Business with Blockchain: Avalanche & Intain",
      description:
        "Coming up on the next Powering Business with Blockchain, Intain joins to talk about IntainMARKETS, a new tokenized marketplace for asset-backed securities, being built on Avalanche.",
      date: "14 Mar 2023",
    },
    {
      img: SFAVegas,
      title: "SFA Vegas 2023 - Digitilization of Markets",
      description:
        "At #SFVegas2023 Intain CEO Siddhartha S would join a panel with leading financial institutions and technology companies to discuss the changes and challenges with respect to the digital future of structured finance.",
      date: "10 February, 2023",
    },
    {
      img: blockchain,
      title: "Blockchain Real Estate Summit 2022",
      description:
        "Our CEO Siddharth joins Avalanche AVAX President, John Wu for a fireside chat at the Blockchain Real Estate Summit to talk about future of MBS and how it is being built on blockchain.",
      date: "23 Sept 2022",
    },

    {
      img: SFAVegas2023,
      title: "SFA Vegas 2023",
      description:
        "Join the Intain team for a scheduled demo at our booth or set up a convenient time through the conference app.",
      date: "14 Feb 2023",
    },
    {
      img: SFABlockChain,
      title: "SFA Vegas 2023: 101 Blockchain",
      description:
        "At #SFVegas2023 Intain's VP Growth Eric Mitzel discusses how blockchain is reaching mainstream adoption in Structured Finance.",
      date: "10 Feb 2023",
    },
  ];
  return (
    <Box className="green-page whatsnew">
      <div className="platform">
        <div>What’s new ?</div>
        <span></span>
      </div>
      <div style={{ marginTop: "50px" }}>
        <Slider {...settings}>
          {slideData.map((slide, index) => (
            <div key={index} className="whatsnew-container">
              <div className="whatsnewbox">
                <div className="imgbox">
                  <a
                    href={slide.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={slide.img} alt={slide.title} />
                    <div>{slide.title}</div>
                    <label>{slide.description}</label>
                  </a>
                </div>
              </div>
              <div className="whatsnewsubimg">
                <img src={whatsnew} alt="img" />
                <label>{slide.date}</label>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </Box>
  );
};

export default WhatsNew;
