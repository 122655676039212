import React from "react";
import Footer from "../../components/Footer/Footer";
import Team from "./Team";
const Company = () => {
  return (
    <div >
      

      <Team />
      <Footer />
    </div>
  );
};

export default Company;
