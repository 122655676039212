import React from "react";
import Header from "../../../components/Header/Header";
import Insightimg from "../../../img/InsightSeven.svg";
import highlevel from "../../../img/intelligentblockchain.svg";
import capturevalue from "../../../img/assetsecurization.svg";
import Footer from "../../../components/Footer/Footer";
const Insight = () => {
  return (
    <div className="custom-managment-page">
      <Header />
      <div className="insight-container" style={{ paddingTop: "100px" }}>
        <img src={Insightimg} alt="Image" />
        <div className="insight-content">
          <header>
            <h2>Intelligent Blockchain for Asset Securitization</h2>
            <h3>
              Enhanced Liquidity and Reduced Risk in Securitisation to address
              the crisis with Non Banking Finance Companies (NBFCs) in India
            </h3>
            <span>Siddhartha & Gary Miller</span>
            <div className="insight-content-date">June 26, 2019</div>
          </header>

          <section className="insight-sub-content">
            <h2>Context</h2>
            <p>
              “Securitization market records 50% growth in Q1[i],” reads an
              Economic Times headline quoting an ICRA report. What is even more
              interesting is that the growth in Pass Through Certificates is 95%
              (Direct Assignments growing at 32%). This is on the back of a 100%
              growth in FY 19[ii], making India, arguably, the fastest growing
              securitization market in the world.
            </p>
            <p>
              Still, at a projected 200 Lakh Cr INR($29B), India’s
              securitization market is one-eightieth (1/80) the size of the US’
              ABS and MBS market[iii][iv], making India a rather nascent market
              given that the size of its economy is about one-eight (1/8) that
              of the US. For the first half of this decade, the US
              securitization market was still recovering from the impact of the
              financial crisis while high savings rate countries like India
              never substantially depended on securitization to finance credit
              growth. In India, a perfect storm of Non Performing Assets (NPAs),
              Asset Liability Mismatch (ALM) and a related, but compounding,
              phenomenon of non-deposit taking entities (most NBFCs) getting a
              larger share of the credit market has resulted in the need for a
              fresh look at the need for capital of lending institutions.
            </p>
            <p>
              If you think of securitization simply as a risk-mitigated means to
              funding, say, someone’s home loan through someone else’s pension
              investments, it makes sense beyond short term triggers like GST
              rates and holding periods etc. But given all the issues we have
              faced, just linking the two words ‘loan’ and ‘pensions’ sounds
              scary as well. Hence, we need to go to first principles and focus
              on a process that enables lenders’ objectives of availability and
              cost of capital and investors’ requirements for the best
              risk-adjusted return.
            </p>
            <img
              src={highlevel}
              alt="Image"
              className="highleve"
            />
            <p>
              Going to the first principles, thinking of securitisation as
              facilitating one person’s investment as another person’s borrowing
            </p>
            <p>
              Recently at a conference in the US, Gary asked the audience why
              they thought most frequent ABS issuers issue quarterly? ‘Because
              it takes a quarter to do a deal,’ he explained. Simply put,
              current securitization processes are inefficient. The time and
              cost involved would still be worth it if they were substantially
              reducing risk, yet that isn’t really the case either. Ultimately,
              securitization participants are actually creating layers of cost
              and layers of opacity in the securitization cycle.
            </p>
            <img
              src={capturevalue}
              alt="Image"
              className="capturevalue1"
            />
            <p>
              All that needs fixing in the Securitisation process –
              <a href="https://www.intainft.com/emulya/emulya.pdf">
                lessons from the US
              </a>
            </p>
            <p>
              Figure 2. All that needs fixing in the Securitisation process
              -lessons from the US[v]
            </p>
          </section>
          <section className="insight-sub-content-1">
            <h2>Blockchain for Securitisation</h2>
            <p>
              With the advent of blockchain technology and the evolution of
              Artificial Intelligence technologies, it is now possible to
              address many of these challenges with Securitization through a
              combination of AI and Blockchain, or using our
              coinage, Intelligent Blockchains.
            </p>
            <p>
              In a report for the Structured Finance Industry Group and Chamber
              of Digital Commerce, Deloitte and Touché, LLP lists various
              advantages of the Securitization process moving to a
              blockchain[vi]:
            </p>
          </section>
          <section className="insight-sub-content-1">
            <p>
              <b>One Version of Truth — </b> Portfolio, eliminating any
              inter-entity or inter-system reconciliation requirement.
            </p>
            <p>
              <b>Comprehensive, Tamper-proof Audit Trail — </b>Every transaction
              on a Blockchain is immutable, thus providing a comprehensive,
              tamper-proof audit trail for regulators and auditors, as well as
              each subsequent investor.
            </p>
            <p>
              <b>Efficient Market — Better Valuation & Price — </b>
              Transparency ensures more efficient market where ‘mark to model’
              isn’t a poor substitute for ‘mark to market’ due to lack of
              liquidity and information asymmetry.{" "}
            </p>
            <p>
              <b>Speed and Certainty — </b>
              Integrated platform and execution of contracts through smart
              contracts ensures near real-time transactions.{" "}
            </p>
            <p>
              Gary, writing for International Business Times, noted that the
              first step to implementing blockchain or distributed ledgers
              technologies (DLT) to benefit the securitization process is to
              simply show that a transaction’s data and calculations can be run
              and automated using DLT[vii]. Blockchain’s connectivity and
              consensus mechanisms will provide trust, provide automation and
              efficiency, immutability, and eliminate or reduce human error.
              While further benefits can be achieved with the advent of digital
              title to assets and issuing digital securities, there is lot that
              can be achieved without waiting for that future.
            </p>
            <p>
              <a href="http://www.othera.io/">Othera in</a> Australia
              and Figure Technologies in the US have built platforms to put both
              the origination and the securitisation process on a blockchain.
              Intain has taken a different approach so that any originator can
              benefit from a blockchain-based securitisation process, even
              without originating loans “on blockchain” or issuing digital
              tokens for the ABS securities. Beyond the benefits of blockchain,
              Intain has used artificial intelligence to automate the due
              diligence process and also uses machine learning to forecast the
              pool and tranche quality for the investor — think of this as a
              credit quality “ticker” based on near real-time data!
            </p>
          </section>
          <section className="insight-sub-content-1">
            <h2>Blockchain for Securitisation</h2>
            <p>
              S&P Global has talked to market participants and, while listing
              the benefits of blockchain for securitisation, it has highlighted
              potential risks[viii]. Both the authors had the opportunity to
              discuss the benefits and the concerns with S&P in the context of
              this report. Some of these risks like Key Technology Partner (KTP)
              risks can be addressed through appropriate contractual and
              governance mechanisms. Interoperability between different
              platforms and system compatibility remains a challenge that the
              blockchain industry is addressing in different ways[ix]. One such
              approach, adopted by Intain, is to use DLT to an extent that it
              brings the benefits of immutability, auditability and transparency
              while working with existing systems and processes as the
              technology evolution for a completely blockchain-centered
              securitisation process evolves over time.
            </p>
          </section>
          <section className="insight-sub-content-1">
            <h2>Future</h2>
            <p>
              While the benefits of securitisation on blockchain are obvious, we
              see it is an evolution rather than a revolution. This evolution
              would cover three facets that may characterise phases of this
              evolution:{" "}
            </p>
            <p>
              <b>1. Blockchain as the Information Rails : </b> Intain is
              currently piloting a securitization transaction for about 20,000
              payday loans on a blockchain platform in India working with a
              leading issuer. The Originator, Issuer (SPV), Investor and
              potentially, the Rating Agency, working of the same blockchain
              with real-time availability of loan level data is something that
              the industry can benefit from now. We see a wider adoption in six
              to twelve months where reconciliation and reporting gets automated
              and all participants and the regulator benefit from auditability
              of the transactions.
            </p>
            <p>
              <b>2. Digital Assets and Digital Securities : </b>A
              ‘dematerialized’ loan contract and a digital security based on the
              units of a securitised tranche almost like a mutual fund unit is
              already a possibility in terms of technology but needs an enabling
              legal and regulatory framework. We should also look at the concept
              of immutable loan records (something akin to an Aadhar of each
              loan) as built by Global Debt Registry (https://gdr.co/ ).{" "}
            </p>
            <p>
              <b>3. Securitisation Marketplace : </b>
              One or a few industry platforms where issuers can get a lower cost
              of capital in return for the additional transparency offered and
              an efficient price discovery mechanism is what we should aim for.
              It will also bring in smaller originators to the market
              facilitating financial inclusion. For the large originators, it
              will allow smaller issuances at much shorter time intervals. On
              the other side, with even boutique, city-based investment advisory
              firms achieving few hundred crores (billions, 1B Rupees = 14M USD)
              in AuMs, a new set of investors could enter the market.
            </p>
            <p>
              <a href="https://www.linkedin.com/in/siddhu75/"> Siddhartha</a>
              &nbsp; held executive leadership and board positions in Financial
              Services operations and technology. He is the Founder and CEO of  &nbsp;
              <a href="https://intainft.com/">Intain</a>
            </p>
            <p>
              <a href="https://www.linkedin.com/in/gary-miller-1397942/">
                Gary Miller
              </a>
               is a veteran securitisation banker who has lived the impact of
              the financial crisis and has been a leading proponent of
              securitisation on blockchain. He is the President of   <a href="https://intainft.com/">Intain</a> Inc.
            </p>
          </section>
          {/* <section className="insight-sub-content-1">
            <img
              src={highlevel}
              alt="Image"
              style={{ width: "803px", height: "703px" }}
            />

            <p>
              The high level functional approach for implementing this use is
              summarized below -The SME and it’s Investors’ profiles will be
              maintained on Hyperledger Fabric. For the initial setup, the
              company decides its initial token supply based on the cash flows
              and associated benchmark of a quarter which is stored in Fabric.
              The token issuance to the investors will be done based on their
              percentage shareholding on the Ethereum platform.
            </p>
            <p>
              Subsequently, at a regular frequency (on a quarterly basis), the
              SME will keep uploading it’s cash flow statement, and the Fabric
              platform will record the details and make updates to the
              benchmark. Based on the benchmark variation, the corresponding
              changes to the overall and investor specific tokens will be pushed
              to the Ethereum platform. The Ethereum platform will maintain the
              changes in the number of tokens of the individual token owners and
              maintain investor wallets.
            </p>
            <p>
              The investors can execute transactions (i.e., transfer, redemption
              of tokens, etc.) on the Ethereum platform. Token Economics At
              Token Issuance, the SME provides the initial cash flow of the
              quarter (CFi), along with the initial total supply of tokens (TSi)
              and the benchmark (BM) associated with it. The tokens are
              distributed to the Investors (incl. founders) proportional to
              their existing equity % in the company.
            </p>
            <p>
              Every quarter, the SME will upload the cash flow document and the
              benchmark will be recalculated as follows -BM(new) =
              (BM(initial)/CFi) * (Cash flow of the current quarter)% variation
              in Benchmark = ((BM(new) — BM(initial))/BM(initial)) * 100Based on
              the % variation in Benchmark, tokens will be burnt as per the
              following rules -If the benchmark variation is negative or is less
              than 50%, no tokens will be burnt.
            </p>
            <p>
              If the benchmark variation is between 50% and 100%, 5% of the
              Initial Token Supply (TSi) of the tokens will be burnt. If the
              benchmark variation is &gt; 100%, 10% of the TSi of tokens will be
              burnt. The tokens will be burnt, till the outstanding tokens will
              be 50% of TSi. When the SME goes public or is acquired, all the
              outstanding tokens will be burnt. The SME specific tokens can be
              exchanged against an exchange-traded platform-specific security
              token (To be developed) and hence traded, leading to liquidity for
              the token owners.
            </p>
          </section>
          <section className="insight-sub-content-1">
            <h2>Technical Architecture</h2>
            <p>
              The architecture of the platform can be defined in three layers
              (as shown above) –The Blockchain Network layer –This layer
              consists of two blockchain networks -Hyperledger Fabric network
              (permissioned blockchain)-It is setup with 2 Orgs, and 4 peers
              nodes (2 peers in each Org). Each Org represents an SME.
            </p>
            <p>
              Fabric CA is used for Identity Management. An SME user will be
              registered and enrolled in Fabric CA. This SME user can then add
              it’s Investor users in the system by registering and enrolling
              them in Fabric CA. There are two Fabric CAs configured for this
              platform, one associated with each Org.
            </p>
            <p>
              Chaincodes are written in Golang for saving the SME cash flow
              document, and the benchmark variation details, along with other
              SME and Investor profile details. These chaincodes are installed
              and instantiated on all the peer nodes.2. Ethereum network (public
              blockchain) -The SME and Investor wallets are created and
              maintained on the Ethereum Ropsten Network.
            </p>
            <p>
              A Smart Contract written in Solidity and deployed on Ethereum
              contains the logic for token creation, issuance and distribution
              to Investors. The smart contract is based on the functionalities
              derived from ERC20, and will be extended to ERC777 and ERC1400
              standards in future.
            </p>
            <p>
              {" "}
              The ERC20 standard includes the following functions -The
              additional functionalities of burn and minting tokens are
              implemented as follows –
            </p>
            <p>
              1 . The wallet addresses of the users and contract address of the
              token are stored in Fabric for reference and authentication
              purposes. The interaction between the two blockchains is achieved
              through RESTful web APIs that contain the business logic of the
              application.
            </p>
            <p>
              2 . The Business Logic(web app) Middle Layer –This layer contains
              the web application code written in a RESTful microservices based
              architecture. The application is built with two microservices as
              follows -The microservice for Capturing the Company Value on
              Fabric -This web application is built using Nodejs. It uses the
              fabric-node-SDK based REST APIs to communicate with the Fabric
              blockchain.
            </p>
            <p>
              The fabric-client based APIs are used to register and enroll users
              in Fabric CA. Every time a user logs into the system, the
              fabric-client is used to check if the user is already registered
              and enrolled. On successful authentication, a JWT token is
              generated for the user that stays valid till the user session
              expires. This token is used as an authentication token for
              accessing any functionalities built upon Fabric.
            </p>
            <p>
              This microservice contains functionalities for maintaining the SME
              and Investor Profiles in Fabric. Apart from this, it contains the
              main logic for maintaining the initial and periodic benchmark
              variation details, along with the details of the uploaded cashflow
              document in each quarter.
            </p>
            <p>
              Cash flow Document Upload and Value Capture API – The SME user
              will upload the cash flow document for each quarter on the
              platform. For the initial setup, the initial benchmark will be
              taken as input from the user and stored in fabric along with other
              details from the document. The number of tokens to be allocated to
              each investor will be calculated and sent to the Ethereum API for
              transferring the tokens to individual investor wallets.
            </p>
            <p>
              In subsequent quarters, a scheduler will read the contents of the
              document uploaded by the SME, will calculate the new benchmark( as
              described in the section Token Economics), and will store the
              details along with the document hash and it’s storage location in
              Fabric.
            </p>
            <p>
              Based on the new benchmark, the number of tokens to be burnt are
              calculated for each investor and sent to the Ethereum APIs, along
              with the investors’ wallet addresses, where the tokens are burnt
              from each investor’s wallet.
            </p>
            <p>
              <b>Reference:</b> Based on paper presented at Hyperledger Member
              Summit, Tokyo 2019
            </p>
          </section> */}
        </div>
      </div>
    </div>
  );
};
const InsightSeven = () => {
  return (
    <div>
      <Insight />
      <Footer />
    </div>
  );
};

export default InsightSeven;
