import React from "react";
import Header from "../../../components/Header/Header";
import EventsIMG from "../../../img/Events3.svg";
import Footer from "../../../components/Footer/Footer";
const Events = () => {
  return (
    <div className="custom-managment-page">
      <Header />
      <div className="insight-container">
        <img src={EventsIMG} alt="Image" />
        <div className="insight-content" style={{ paddingBottom: "0px" }}>
          <header>
            <h2>SFA Vegas 2023 – Digitilization of Markets</h2>
          </header>
        </div>
        <section className="insight-sub-content-1">
          <p>
            At #SFVegas2023 Intain CEO Siddhartha S would join a panel with
            leading financial institutions and technology companies to discuss
            the changes and challenges with respect to the digital future of
            structured finance.{" "}
          </p>
        </section>
      </div>
    </div>
  );
};

const Events3 = () => {
  return (
    <div>
      <Events />
      <Footer />
    </div>
  );
};

export default Events3;
