import React from "react";
import Header from "../../../components/Header/Header";
import Insightimg from "../../../img/InsightFour.svg";
import intaindesign from "../../../img/intaindesign.svg";
import Footer from "../../../components/Footer/Footer";
const Insight = () => {
  return (
    <div className="custom-managment-page">
      <Header />
      <div className="insight-container" style={{ paddingTop: "100px" }}>
        <img src={Insightimg} alt="Image" />
        <div className="insight-content">
          <header>
            <h2>The Boring Blockchain Initiative </h2>
            <h3>
              Among Plumbers and Punters in the Blockchain World, We are the
              Plumbers{" "}
            </h3>
            <span>Siddhartha S.</span>
            <div className="insight-content-date">October 15, 2019</div>
          </header>

          <section className="insight-sub-content">
            <p>
              A few weeks back, just after attending ABS East, one of the
              biggest gatherings of the structured finance industry, I did a
              LinkedIN post titled Making Blockchain Boring. I talked about a
              panel on Digital Securities where John Mizzi of Bond.One talked of
              ‘payment processing and record keeping solution for debt
              instruments’ as their offering while Marcus Austin of Citi added
              ‘so much data moving around so if you can prove provenance of data
              across all parties, that is what we are looking at’. Since this
              was a gathering of financial institutions, some bias towards a
              more prosaic approach was understandable but still a change from a
              year back was clearly discernible.{" "}
            </p>
            <p>
              Couple of weeks after my post, I found another reference to
              ‘boring blockchain’ in a blog about Hyperledger’s Burrow.
              Suddenly, from claiming to
              <a href="https://steemit.com/blockchain/@calamus056/my-blockchain-concept-to-achieve-world-peace">
                 achieve world peace through blockchain
              </a>
              ,
              <b>
                talking of blockchain being boring seems to be becoming
                fashionable!
              </b>
            </p>
            <p>
              Since Intain launched its platform for Structured Finance, one
              standard feature of pitch has been a stress on an evolutionary
              approach.
            </p>
            <img
              src={intaindesign}
              alt="Image"
              className="intaindesign"
            />
            <p>
              But in many conferences, we had to feel defensive about our
              approach. I must acknowledge that, at times, we had self-doubt. Is
              our mindset, programmed because of the financial services business
              and technology background of leadership team, making us too
              conservative? I attended a Binance conference in Singapore and I
              felt I had landed on another planet as no one talked my language
              and I didn’t understand the language that was being spoken.
            </p>
            <p>
              Initially, even financial institutions tried to put the business
              model ahead of the technology hence consortiums and marketplaces
              in areas like trade finance were among the earliest use cases. So
              many of these were based on Ethereum. But with number of forks (in
              the blockchain and among the founders) and 
              <a href="https://coingape.com/ethereum-vitalik-buterin-scam-calling/">
                Vitalik Buterin’s own pitch about Ethereum 2.0 
              </a>
              , we now know that these consortiums were built on a very nascent
              technology. Recently, an opinion piece in FT{" "}
              <b>
                talked about how many projects started with the technology
                rather than the solution.
              </b>
            </p>
            <p>
              A{" "}
              <b>
                distributed ledger so that no reconciliation is needed across
                transacting parties{" "}
              </b>{" "}
              and <b>an immutable audit trail </b>
              itself makes the blockchain technology priceless for financial
              institutions. Yes, it brings up the DLT vs Blockchain debate but
              it is immaterial as long as a problem is solved using a technology
              innovation. Billions of dollars have been spent by institutions on
              achieving ‘single source of truth’ with little success. Look at
              every desk with dual monitors on an operations floor — it is being
              used to either match data between two systems or enter data from
              one system into another. Having spent many years managing
              financial services operations, I would estimate number of people
              just doing reconciliations in some form across the industry to be
              upwards of a million. It is not only the cost of ‘recons’ but the
              fact that reconciled data makes automation of all the subsequent
              steps in the workflow much simpler.
            </p>
            <p>
              In our platform for Structured Finance, where we use blockchain as
              information rails (the ‘boring blockchain’ or DLT, if you wish),
              <b>
                the zero recon and immutable audit trail enables both efficiency
                and transparency.{" "}
              </b>
              Combined with cutting edge reporting and data visualization tools,
              it ensures real-time information on-demand for all stakeholders.
              Suddenly, with use of blockchain, both process automation and data
              visualization tools becomes so much more powerful. Starting with
              the problem and building and enterprise grade software solution
              using of technology stack of blockchain is a critical part.
              Blockchain as the start of the thought process looking for a use
              case, to use a cliché, is a hammer looking for a nail.
            </p>
            <p>
              I believe that the <b>philosophy</b> (border-less world, no
              central authority), <b>economics</b>(tokens, no inter-mediation)
              and <b>technology</b> (immutability, distributed ledger) will not
              converge in every use case and, possibly in none,in the early
              stages. Since we, the proponents of the blockchain technology,
              always draw parallels with the internet,{" "}
              <b>
                good to remember that ‘protocol-internet-book seller -seller of
                multiple goods-marketplace’ was an evolution
                ‘protocol-internet-book seller -seller of multiple
                goods-marketplace’ was an evolution{" "}
              </b>{" "}
              and the protocol and the marketplace didn’t happen together.and
              the protocol.
            </p>
            <p>So here’s to the boring blockhain!</p>
            <p>
              (I had written this more than a year back, but as focus on Bitcoin
              investing and related startups takes center stage, it is worth
              remembering that there is a technology underlying this which has
              many less fancy, but as impactful use cases){" "}
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};
const InsightFive = () => {
  return (
    <div>
      <Insight />
      <Footer />
    </div>
  );
};

export default InsightFive;
