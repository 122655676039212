import React, { useEffect, useState } from "react";
import inTainLOgo from "../../img/intainlogo-3.png";
import { ArrowDropDown } from "@mui/icons-material";
// import { Popover, OverlayTrigger } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import {
 
  Tooltip,
 
} from "@mui/material";
import moon from "../../img/moon-svgrepo-com.svg";
import sun from "../../img/sun-svgrepo-com.svg";
import { Popover } from "antd";
// import IconButton from "@mui/material/IconButton";
// import { Brightness7, Brightness4 } from "@mui/icons-material";
import { MdOutlineMenu } from "react-icons/md";
import { MdClose } from "react-icons/md";

const Header = () => {
  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = useState(
    sessionStorage.getItem("activeMenu") || "home" // Get active menu from sessionStorage
  );

  const [isDarkMode, setIsDarkMode] = useState(
    sessionStorage.getItem("dark-theme") === "dark"
  );

  const [isSun, setIsSun] = useState(true);
  const [click, setClick] = useState(false);

  const isActive = (menu) => (activeMenu === menu ? "active" : "");

  const toggleTheme = () => {
    const newTheme = !isDarkMode;
    setIsDarkMode(newTheme);

    // Update the theme attribute on the document and store the preference in sessionStorage
    const theme = newTheme ? "dark" : "light";
    document.documentElement.setAttribute("dark-theme", theme);
    sessionStorage.setItem("dark-theme", theme);
    setIsSun(!isSun);
  };

  useEffect(() => {
    const currentTheme = sessionStorage.getItem("dark-theme") || "light";
    document.documentElement.setAttribute("dark-theme", currentTheme);
    setIsDarkMode(currentTheme === "dark");
  }, []);

  const handleMenuClick = (menu) => {
    setActiveMenu(menu); // Set active menu state
    sessionStorage.setItem("activeMenu", menu); // Save active menu in sessionStorage

    // Perform navigation
    if (menu === "home") {
      navigate("/"); // Navigate to home
    } else if (menu === "company") {
      navigate("/company"); // Navigate to company
    } else if (menu === "resources") {
      navigate("/resource"); // Navigate to resources
    } else if (menu === "contact") {
      navigate("/contact-us"); // Navigate to contact-us
    } else if (menu === "signin") {
      navigate("/signin"); // Navigate to signin
    }
  };

  const companyPopoverContent = (
    <ul className="popover-list">
      <li>
        <a href="/intain-admin" className="popover-link">
          INTAIN<span>ADMIN</span>
        </a>
      </li>
      <li>
        <a href="/intain-markets" className="popover-link">
          INTAIN<span>MARKETS</span>
        </a>
      </li>
      <li>
        <a
          href="https://in-d.ai/"
          className="popover-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          AI with IN-D
        </a>
      </li>
    </ul>
  );
  const companyPopover = (
    <ul className="popover-list">
      <li>
        <a className="popover-link" onClick={() => navigate("/company")}>
          About Us
        </a>
      </li>
      <li>
        <a
          onClick={() => navigate("/company?section=news-events")}
          className="popover-link"
        >
          News and Events
        </a>
      </li>
      <li>
        <a
          onClick={() => navigate("/company?section=careers")}
          className="popover-link"
        >
          Careers
        </a>
      </li>
    </ul>
  );

  const Hamburger = (
    <MdOutlineMenu
      className="HamburgerMenu"
      size="30px"
      color="var(--black)"
      onClick={() => setClick(!click)}
    />
  );

  const Close = (
    <MdClose
      className="HamburgerMenu"
      size="30px"
      color="var(--black)"
      onClick={() => setClick(!click)}
    />
  );

  return (
    <header className="header">
      <div className="logo">
        <img
          src={inTainLOgo}
          alt="Logo"
          onClick={() => navigate("/")}
          style={{ cursor: "pointer" }}
        />
      </div>
      <div className="HamburgerWrapper">
        {click ? Close : Hamburger}
        </div>
    
      {/* {click && ( */}
        <nav className={`nav ${click ? "show" : ""}`}>
          <ul>
            <li>
              <button
                className={isActive("home")}
                onClick={() => handleMenuClick("home")}
              >
                Home
              </button>
            </li>
            <li className="menu-item">
              <Popover
                content={companyPopoverContent}
                trigger="click"
                placement="bottom"
              >
                <button
                  className={isActive("platform")}
                  onClick={() => handleMenuClick("platform")}
                  style={{ alignItems: "center" }}
                >
                  Platform{" "}
                  <ArrowDropDown
                    className={`arrow-icon ${
                      activeMenu === "platform" ? "active" : ""
                    }`}
                  />
                </button>
              </Popover>
            </li>
            <li className="menu-item">
              <Popover
                content={companyPopover}
                trigger="click"
                placement="bottom"
              >
                <button
                  className={isActive("company")}
                  onClick={() => handleMenuClick("company")}
                >
                  Company{" "}
                  <ArrowDropDown
                    className={`arrow-icon ${
                      activeMenu === "platform" ? "active" : ""
                    }`}
                  />
                </button>
              </Popover>
            </li>
            <li>
              <button
                className={isActive("resources")}
                onClick={() => handleMenuClick("resources")}
              >
                Resources
              </button>
            </li>
            <li>
              <button
                className={isActive("contact")}
                onClick={() => handleMenuClick("contact")}
              >
                Contact Us
              </button>
            </li>
            <li>
              <button
                className={isActive("signin")}
                onClick={() => handleMenuClick("signin")}
              >
                Sign In
              </button>
            </li>
            <li>
            <Tooltip
              title={
                isDarkMode ? "Switch to Dark Mode" : "Switch to Light Mode"
              }
              arrow
            >
              <div style={{ position: "relative", bottom: "15px" }}>
                <input
                  type="checkbox"
                  id="darkmode-toggle"
                  checked={isDarkMode}
                  onChange={toggleTheme}
                />
                <label htmlFor="darkmode-toggle" className="darkmode-label">
                  <img src={moon} alt="moon" className="moon" />
                  <img src={sun} alt="sun" className="sun" />
                </label>
              </div>
            </Tooltip>
          </li>
            {/* <li>
              <Tooltip
                title={
                  isDarkMode ? "Switch to Dark Mode" : "Switch to Light Mode"
                }
                arrow
              >
                <IconButton onClick={toggleTheme} color="inherit">
                  {isSun ? (
                    <Brightness7 />
                  ) : (
                    <Brightness4 style={{ transform: "rotate(180deg)" }} />
                  )}
                </IconButton>
              </Tooltip>
            </li> */}
          </ul>
        </nav>
      {/* )}  */}
     
    </header>
  );
};

export default Header;
