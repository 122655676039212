import React, { useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Box, Button, Dialog } from "@mui/material";
import { Close } from "@mui/icons-material";
import IntainMarketimg from "../../img/enhance-liquidity-removebg-preview 1.png";
import im01 from "../../img/Group-961 1.png";
import shield from "../../img/shield.png";
import investor from "../../img/investor-insi 1.png";
import workflow from "../../img/wokflw 1.png";
import workflow1 from "../../img/document-management 1.png";
import ReCAPTCHA from "react-google-recaptcha";
import { ContactApi } from "../../Actions/Action";

const RECAPTCHA_SITE_KEY = "6LdWIYYqAAAAAFKEtiNjf5gz0kSKfZ_jIGNAlybI";

const Hero = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    company: "",
    role: "",
    interest: "",
    companyMail: "",
    message: "",
    isRobot: false,
  });

  const closeDeal = () => {
    setDialogOpen(false);
    setFormData([]);
  };

  const roleOptions = [
    { value: "Accounting Firm", label: "Accounting Firm" },
    { value: "Aggregator", label: "Aggregator" },
    { value: "Arrangers / Advisory", label: "Arrangers / Advisory" },
    { value: "Asset Manager", label: "Asset Manager" },
    { value: "Bank Lender", label: "Bank Lender" },
    { value: "Broker Dealer", label: "Broker Dealer" },
    { value: "CDO / CLO Manager", label: "CDO / CLO Manager" },
    { value: "Individual", label: "Individual" },
    { value: "Institutional Investor", label: "Institutional Investor" },
    { value: "Issuer", label: "Issuer" },
    { value: "Law Firm", label: "Law Firm" },
    { value: "Mortgage Insurer", label: "Mortgage Insurer" },
    { value: "Originator", label: "Originator" },
    {
      value: "Other Financial Institution",
      label: "Other Financial Institution",
    },
    { value: "Rating Agency", label: "Rating Agency" },
    { value: "Servicer", label: "Servicer" },
    { value: "Trustee", label: "Trustee" },
    { value: "Underwriter", label: "Underwriter" },
  ];
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    await ContactApi(formData); // Assume formData is the data object you're passing to the API
    setFormData({
      name: "",
      company: "",
      role: "",
      interest: "",
      companyMail: "",
      message: "",
      isRobot: false,
    });
    setDialogOpen(false);

    setLoader(false);
  };

  const onRecaptchaChange = (value) => {
    console.log("Recaptcha value:", value);
    // Set the formData 'isRobot' field to true or false based on the recaptcha result
    setFormData({ ...formData, isRobot: value ? true : false });
  };
  return (
    <div className="page">
      <div className="hero-container">
        <div className="left-panel">
          <h1 className="title">
            <span className="custom-title">
              First Structure Credit Marketplace
            </span>{" "}
            with On-Chain Administration <br />
            <p className="description">
              INTAIN<span>MARKETS</span> is the first tokenized structured
              credit platform with its own blockchain-enabled administration for
              providing trust and transparency that digital assets and
              securities were missing.{" "}
            </p>
          </h1>

          <Button onClick={() => setDialogOpen(true)} variant="contained">
            Request a Demo
          </Button>

          <div className="underline"></div>
        </div>
        <div className="right-panel flex-item-img">
          <img
            src={IntainMarketimg}
            alt="image"
            // style={{ height: "72vh", marginRight: "5.5rem" }}
          />
        </div>
      </div>

      <>
        <Dialog
          onClose={closeDeal}
          aria-labelledby="customized-dialog-title"
          open={dialogOpen}
        >
          <div className="demo-container custom-managment-form">
            <div className="closeicon">
              <Close onClick={closeDeal} className="icon"></Close>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="contact-us-container-form-input">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="contact-us-container-form-input">
                <label htmlFor="company">Company</label>
                <input
                  type="text"
                  id="company"
                  name="company"
                  value={formData.company}
                  onChange={handleInputChange}
                />
              </div>
              <div className="contact-us-container-form-input">
                <label htmlFor="role">Role</label>
                <select
                  id="role"
                  name="role"
                  value={formData.role}
                  onChange={handleInputChange}
                >
                  <option value="">Select Role</option>
                  {roleOptions.map((role) => (
                    <option key={role.value} value={role.value}>
                      {role.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="contact-us-container-form-input">
                <label htmlFor="interest">Interest</label>
                <select
                  id="interest"
                  name="interest"
                  value={formData.interest}
                  onChange={handleInputChange}
                >
                  <option value="">Select Interest</option>
                  <option value="intainadmin">
                    IntainADMIN(Integrated Data Operations)
                  </option>
                  <option value="intainmarket">
                    IntainMARKETS(Digital Issuance and Admin)
                  </option>
                  <option value="intainai">
                    IntainAI(Automated Document Processing)
                  </option>
                  <option value="Genear">General Enquiry</option>
                </select>
              </div>
              <div className="contact-us-container-form-input">
                <label htmlFor="companyMail">Company Mail ID</label>
                <input
                  type="email"
                  id="companyMail"
                  name="companyMail"
                  value={formData.companyMail}
                  onChange={handleInputChange}
                />
              </div>
              <div className="contact-us-container-form-input">
                <label htmlFor="message">Message</label>
                <textarea
                  style={{ height: "100px" }}
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  maxLength="500"
                />
              </div>
              <div className="recaptcha-wrapper">
                <ReCAPTCHA
                  sitekey={RECAPTCHA_SITE_KEY}
                  onChange={onRecaptchaChange}
                />
              </div>
              <button type="submit">{loader ? "Loading..." : "Submit"}</button>
              <label style={{ paddingTop: "20px" }}>
                By clicking submit application, You understand and agree to the
                terms of use and <a href="/privacy-policy">privacy policy.</a>
              </label>
            </form>
          </div>
        </Dialog>
      </>
    </div>
  );
};

const One = () => {
  return (
    <Box className="flex-container">
      <div className="flex-item">
        <img src={im01} />
      </div>
      <div className="flex-item">
        <div className="num">01</div>
        <div className="underline underline--large"></div>

        <label className="custom-label">
          Tokenization is only Part of the
          <br /> Solution
        </label>
        <p className="custom-para">
          The growth of tokens and digital securities has highlighted the
          liquidity benefits of digital assets on a blockchain. This also
          democratizes investment opportunities. However, current token
          platforms do not provide investors with a view into the value on the
          underlying asset.{" "}
        </p>
      </div>
    </Box>
  );
};

const Two = () => {
  return (
    <Box className="flex-container mt-5">
      <div className="flex-item">
        <div className="num">02</div>
        <div className="underline underline--large"></div>

        <label className="custom-label">
          Intain Adds Trust to Digital <br /> Assets
        </label>
        <p className="custom-para">
          Intain’s platform provides a token-specific ‘NAV’, giving token
          holders the ability to track their tokenized assets in real-time. With
          Intain, investors have a holistic insight into the underlying asset,
          facilitating better valuations and better market efficiency.{" "}
        </p>
      </div>

      <div className="flex-item flex-item-image">
        <img src={shield} className="polygon-img" />
      </div>
    </Box>
  );
};
const Three = () => {
  return (
    <Box className="flex-container mt-5">
      <div className="flex-item">
        <img src={investor} />
      </div>
      <div className="flex-item">
        <div className="num">03</div>
        <div className="underline underline--large"></div>

        <label className="custom-label">Investor Insights</label>

        <p className="custom-para">
          Investment Bankers and Investors get real-time view into the
          underlying asset as well as analytics based on the asset pool.{" "}
        </p>
      </div>
    </Box>
  );
};
const Four = () => {
  return (
    <Box className="flex-container mt-5">
      <div className="flex-item">
        <div className="num">04</div>
        <div className="underline underline--large"></div>

        <label className="custom-label">Workflow automation</label>

        <p className="custom-para">
          Structuring tranches, payment waterfalls, and automated report
          generation is made seamless.{" "}
        </p>
      </div>

      <div className="flex-item flex-item-image">
        <img src={workflow} />
      </div>
    </Box>
  );
};

const Five = () => {
  return (
    <Box className="flex-container mt-5 pb-5">
      <div className="flex-item">
        <img src={workflow1} />
      </div>
      <div className="flex-item">
        <div className="num">05</div>
        <div className="underline underline--large"></div>

        <label className="custom-label">Document Management</label>
        <p className="custom-para">
          Intain uses an AI engine to digitize and extract relevant content from
          documents, Asset documents, collateral, servicer agreement and
          security contracts – our software can read these documents and onboard
          any relevant data to the Intain platform.
        </p>
      </div>
    </Box>
  );
};
const IntainMarket = () => {
  return (
    <div>
      <Header />
      <div className="intainAdmin">
        <Hero />
        <One />
        <Two />
        <Three />
        <Four />
        <Five />
        <Footer />
      </div>
    </div>
  );
};

export default IntainMarket;
