import React, { useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Box, Button, Dialog } from "@mui/material";
import platformbanner from "../../img/platform-banner2-removebg-1.png";
import IOne from "../../img/intainadmin01.svg";
import Layer from "../../img/layer-2-1.png";
import Polygon from "../../img/polygon-7.svg";
import three from "../../img/3-removebg-preview.png";
import four from "../../img/4-removebg-preview.png";
import five from "../../img/5-removebg-preview.png";
import ReCAPTCHA from "react-google-recaptcha";
import { Close } from "@mui/icons-material";
import { ContactApi } from "../../Actions/Action";


const RECAPTCHA_SITE_KEY = "6LdWIYYqAAAAAFKEtiNjf5gz0kSKfZ_jIGNAlybI";

const arrowLabels = [
  "One Version of Truth",
  "Automation",
  "Enabling Digital Assets",
  "Transparency Compliance",
];
const Hero = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    company: "",
    role: "",
    interest: "",
    companyMail: "",
    message: "",
    isRobot: false,
  });

  const closeDeal = () => {
    setDialogOpen(false);
    setFormData([]);
  };

  const roleOptions = [
    { value: "Accounting Firm", label: "Accounting Firm" },
    { value: "Aggregator", label: "Aggregator" },
    { value: "Arrangers / Advisory", label: "Arrangers / Advisory" },
    { value: "Asset Manager", label: "Asset Manager" },
    { value: "Bank Lender", label: "Bank Lender" },
    { value: "Broker Dealer", label: "Broker Dealer" },
    { value: "CDO / CLO Manager", label: "CDO / CLO Manager" },
    { value: "Individual", label: "Individual" },
    { value: "Institutional Investor", label: "Institutional Investor" },
    { value: "Issuer", label: "Issuer" },
    { value: "Law Firm", label: "Law Firm" },
    { value: "Mortgage Insurer", label: "Mortgage Insurer" },
    { value: "Originator", label: "Originator" },
    {
      value: "Other Financial Institution",
      label: "Other Financial Institution",
    },
    { value: "Rating Agency", label: "Rating Agency" },
    { value: "Servicer", label: "Servicer" },
    { value: "Trustee", label: "Trustee" },
    { value: "Underwriter", label: "Underwriter" },
  ];
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    await ContactApi(formData); // Assume formData is the data object you're passing to the API
    setFormData({
      name: "",
      company: "",
      role: "",
      interest: "",
      companyMail: "",
      message: "",
      isRobot: false,
    });
    setDialogOpen(false);
    setLoader(false);
  };
  const onRecaptchaChange = (value) => {
    console.log("Recaptcha value:", value);
    setFormData({ ...formData, isRobot: value ? true : false });
  };
  return (
    <div>
      <Box className="hero-container">
        <div className="left-panel">
          <h1 className="title">
            <span className="custom-title">
              Trust, Transparency and Efficiency
            </span>{" "}
            in <br />
            Structured Finance
          </h1>
          <p className="description">
            Digital issuance integrated with automated administration is here,{" "}
            <br />
            with all stakeholders connected on a blockchain network.{" "}
          </p>
          <Button onClick={() => setDialogOpen(true)} variant="contained">
            Request a Demo
          </Button>

          <div className="underline"></div>
        </div>
        <div className="right-panel">
          <img
            src={platformbanner}
            alt="image"
            className="intain-admin-container-right"
          />
        </div>
      </Box>
      <>
        {" "}
        <>
          <Dialog
            onClose={closeDeal}
            aria-labelledby="customized-dialog-title"
            open={dialogOpen}
          >
            <div className="demo-container custom-managment-form">
              <div className="closeicon">
                <Close onClick={closeDeal} className="icon"></Close>
              </div>

              <form onSubmit={handleSubmit}>
                <div className="contact-us-container-form-input">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="contact-us-container-form-input">
                  <label htmlFor="company">Company</label>
                  <input
                    type="text"
                    id="company"
                    name="company"
                    value={formData.company}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="contact-us-container-form-input">
                  <label htmlFor="role">Role</label>
                  <select
                    id="role"
                    name="role"
                    value={formData.role}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Role</option>
                    {roleOptions.map((role) => (
                      <option key={role.value} value={role.value}>
                        {role.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="contact-us-container-form-input">
                  <label htmlFor="interest">Interest</label>
                  <select
                    id="interest"
                    name="interest"
                    value={formData.interest}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Interest</option>
                    <option value="intainadmin">
                      IntainADMIN(Integrated Data Operations)
                    </option>
                    <option value="intainmarket">
                      IntainMARKETS(Digital Issuance and Admin)
                    </option>
                    <option value="intainai">
                      IntainAI(Automated Document Processing)
                    </option>
                    <option value="Genear">General Enquiry</option>
                  </select>
                </div>
                <div className="contact-us-container-form-input">
                  <label htmlFor="companyMail">Company Mail ID</label>
                  <input
                    type="email"
                    id="companyMail"
                    name="companyMail"
                    value={formData.companyMail}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="contact-us-container-form-input">
                  <label htmlFor="message">Message</label>
                  <textarea
                    style={{ height: "100px" }}
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    maxLength="500"
                  />
                </div>
                <div className="recaptcha-wrapper">
                  <ReCAPTCHA
                    sitekey={RECAPTCHA_SITE_KEY}
                    onChange={onRecaptchaChange}
                  />
                </div>
                <button type="submit">
                  {loader ? "Loading..." : "Submit"}
                </button>
                <label style={{ paddingTop: "20px" }}>
                  By clicking submit application, You understand and agree to
                  the terms of use and{" "}
                  <a href="/privacy-policy">privacy policy.</a>
                </label>
              </form>
            </div>
          </Dialog>
        </>
      </>
    </div>
  );
};

const One = () => {
  return (
    <Box className="flex-container">
      <div className="flex-item">
        <img src={IOne} alt="Illustration" />
      </div>
      <div className="flex-item">
        <div className="num">01</div>
        <div className="underline underline--large"></div>
        <label className="custom-label">Structured Finance Today</label>
        <p className="custom-para">
          Multiple systems, siloed data and layered costs make transactions
          inefficient, expensive, and opaque.
        </p>
      </div>
    </Box>
  );
};

const Two = () => {
  return (
    <Box className="flex-container mt-5">
      <div className="flex-item">
        <div className="num">02</div>
        <div className="underline underline--large"></div>

        <label className="custom-label">
          Structured Finance with Intain
        </label>
        <p className="custom-para">
          Intain enables efficient, and transparent administration and <br />{" "}
          servicing.
        </p>
      </div>

      <div className="layerimg flex-item mart-5">
        <div className="polygon">
          {arrowLabels.map((label, index) => (
            <div className="arrow-label-row" key={index}>
              <img src={Polygon} alt={`Arrow icon for ${label}`} />
              <label>{label}</label>
            </div>
          ))}
        </div>
        <img src={Layer} className="polygon-img" />
      </div>
    </Box>
  );
};
const Three = () => {
  return (
    <Box className="flex-container mt-5">
      <div className="flex-item">
        <img src={three} />
      </div>
      <div className="flex-item">
        <div className="num">03</div>
        <div className="underline underline--large"></div>

        <label className="custom-label">Structured Finance with Intain</label>
        <div className="custom-label-light">
          Asset Data Validation enabled by AI
        </div>
        <p className="custom-para">
          Intain enables efficient, and transparent administration and
          servicing.
        </p>
      </div>
    </Box>
  );
};
const Four = () => {
  return (
    <Box className="flex-container mt-5">
      <div className="flex-item">
        <div className="num">04</div>
        <div className="underline underline--large"></div>

        <label className="custom-label">
          Granular, Customizable, and Comprehensive Insights
        </label>
        <div className="custom-label-light">Real time Portfolios Ratings </div>
        <p className="custom-para">
          In addition to providing real-time, asset level data, Intain enables
          investors to have a complete view into their asset pools. Using an
          AI-enabled Data Visualization tool, investors can get customized
          reports and comprehensive insight into their portfolio.{" "}
        </p>
      </div>

      <div className="flex-item flex-item-image">
        <img
          src={four}
          // className="polygon-img"
          // style={{ height: "300px", width: "110%" }}
          // style={{ width: " 736px", height: "425px" }}
        />
      </div>
    </Box>
  );
};

const Five = () => {
  return (
    <Box className="flex-container mt-5 pb-5">
      <div className="flex-item">
        <img src={five} />
      </div>
      <div className="flex-item">
        <div className="num">05</div>
        <div className="underline underline--large"></div>

        <label className="custom-label">Automation</label>
        <div className="custom-label-light">
          Smart Contracts for All Deal Structures
        </div>
        <p className="custom-para">
          To streamline the deal process for ABS, MBS, and Warehouse Lending,
          Intain’s smart contracts automate the establishment and maintenance of
          deals structures, removing the need for time-consuming data
          reconciliation and calculations for monthly statements.{" "}
        </p>
      </div>
    </Box>
  );
};
const IntainAdmin = () => {
  return (
    <div>
      <Header />
      <div className="intainAdmin">
        <Hero />
        <One />
        <Two />
        <Three />
        <Four />
        <Five />
        <Footer />
      </div>
    </div>
  );
};

export default IntainAdmin;
